import React from 'react';
import moment from 'moment-timezone';
import Modal from 'react-modal';
import { FaCheck, FaTimes, FaStepForward, FaFlag } from 'react-icons/fa';
import './ValidationPopup.css';

const ValidationPopup = ({
    bet,
    currentIndex,
    totalBets,
    onValidate,
    onChallenge,
    onSkip,
    onEnd,
    showValidateConfirm,
    showChallengeConfirm,
    onConfirm,
    onCancel
}) => {
    const formatTimestamp = (timestamp) => {
        return moment(timestamp).format('MMMM Do YYYY, h:mm:ss a');
    };

    const ConfirmationModal = ({ isOpen, onConfirm, onCancel, action }) => (
        <Modal
            isOpen={isOpen}
            onRequestClose={onCancel}
            className="confirmation-modal"
            overlayClassName="confirmation-overlay"
        >
            <div className="confirmation-content">
                <h3>{action === 'validate' ? 'Confirm Validation' : 'Confirm Challenge'}</h3>
                <p>{action === 'validate' 
                        ? 'Are you sure you want to validate this outcome?' 
                        : 'Are you sure you want to challenge this outcome?'
                    }
                </p>
                <div className="confirmation-buttons">
                    <button className="confirm-button" onClick={onConfirm}>Confirm</button>
                    <button className="cancel-button" onClick={onCancel}>Cancel</button>
                </div>
            </div>
        </Modal>
    );

    return (
        <div className="validation-popup-container">
             <div id="modal-root" />
            <div className="validation-popup">
                <div className="validation-header">
                    <h2>Bet Validation Required</h2>
                    <div className="progress-indicator">{currentIndex + 1} of {totalBets} bets</div>
                </div>
                <div className="bet-details">
                    <div className="bet-info">
                        <h3>Bet Details</h3>
                        <p><strong>Details:</strong> {bet.betDetails || ''}</p>
                        <p><strong>Amount:</strong> ${bet.amount || 0}</p>
                        <p><strong>League:</strong> {bet.leagueName || ''}</p>
                        <p><strong>Requester:</strong> {bet.requester || ''}</p>
                        <p><strong>Submitted:</strong> {formatTimestamp(bet.timestamp)}</p>
                        <p><strong>Proposed Outcome:</strong> {bet.outcome || ''}</p>
                    </div>
                    <div className="validation-actions">
                        <button className="validate-button" onClick={onValidate}><FaCheck /> Validate Outcome</button>
                        <button className="challenge-button" onClick={onChallenge}><FaFlag /> Challenge Outcome</button>
                    </div>
                    <div className="navigation-actions">
                        {currentIndex < totalBets - 1 ? (
                            <button className="skip-button" onClick={onSkip}><FaStepForward /> Skip to Next</button>
                        ) : (
                            <button className="end-button" onClick={onEnd}><FaTimes /> End Session</button>
                        )}
                    </div>
                </div>

                {/* Confirmation Modals */}
                <ConfirmationModal isOpen={showValidateConfirm} onConfirm={onConfirm} onCancel={onCancel} action="validate" />
                <ConfirmationModal isOpen={showChallengeConfirm} onConfirm={onConfirm} onCancel={onCancel} action="challenge" />
            </div>
        </div>
    );
};

export default ValidationPopup;
