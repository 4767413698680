import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from './AuthContext';
import { 
    FaTrophy, 
    FaChartLine,
    FaGamepad,
    FaDollarSign,
    FaFire,
    FaBolt,
    FaCalendarAlt,
    FaPercentage,
    FaArrowLeft
} from 'react-icons/fa';
import './LeagueStats.css';

const LeagueStats = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [stats, setStats] = useState(null);
    const { user } = useAuth();
    const token = localStorage.getItem('token');

    useEffect(() => {
        fetchLeagueStats();
    }, [id]);

    const fetchLeagueStats = async () => {
        try {
            const response = await axios.post(
                'https://b9s45cfxpe.execute-api.eu-west-3.amazonaws.com/prod/league_global_statistics',
                { league_id: id },
                { headers: { 'Authorization': `Bearer ${token}` } }
            );

            if (response.status === 200) {
                setStats(response.data.statistics);
            }
        } catch (error) {
            console.error('Failed to fetch league statistics:', error);
            setError('Failed to fetch league statistics');
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return (
            <div className="loading-container">
                <div className="loading-spinner"></div>
                <p>Loading statistics...</p>
            </div>
        );
    }

    if (error) {
        return <div className="error-message">{error}</div>;
    }

    const StatCard = ({ title, data, icon: Icon, valueKey = 'value', nameKey = 'username' }) => (
        <div className="stat-leaderboard-card">
            <div className="card-header">
                <Icon className="stat-icon" />
                <h3>{title}</h3>
            </div>
            <div className="leaderboard-list">
                {data?.map((item, index) => (
                    <div key={index} className="leaderboard-item">
                        <span className="rank">{index + 1}</span>
                        <span className="username">{item[nameKey]}</span>
                        <span className="value">{item[valueKey]}</span>
                    </div>
                ))}
            </div>
        </div>
    );

    return (
        <div className="league-stats-container">
            <div className="league-stats-header">
                <button className="back-button" onClick={() => navigate(`/leagues/${id}`)}>
                    <FaArrowLeft /> Back to League
                </button>
                <button onClick={() => navigate(`/leagues/${id}/outcome`)}>Detailed Outcome</button>
                <h1>League Statistics</h1>
            </div>

            <div className="stats-cards">
                <StatCard 
                    title="Outcome"
                    icon={FaDollarSign}
                    data={stats?.earnings}
                    valueKey="amount"
                />
                
                <StatCard 
                    title="Highest Win Rate"
                    icon={FaTrophy}
                    data={stats?.winRates}
                    valueKey="winRate"
                />
                
                <StatCard 
                    title="Most Bets Created"
                    icon={FaGamepad}
                    data={stats?.betsCreated}
                    valueKey="count"
                />
                
                <StatCard 
                    title="Most Bets Joined"
                    icon={FaChartLine}
                    data={stats?.betsJoined}
                    valueKey="count"
                />
                
                <StatCard 
                    title="Best winning Streak"
                    icon={FaBolt}
                    data={stats?.bestStreaks}
                    valueKey="streak"
                />
                
                <StatCard 
                    title="Current winning Streak"
                    icon={FaFire}
                    data={stats?.currentStreaks}
                    valueKey="streak"
                />
                
                <StatCard 
                    title="Highest Participation"
                    icon={FaPercentage}
                    data={stats?.participation}
                    valueKey="rate"
                />
            </div>
        </div>
    );
};

export default LeagueStats;