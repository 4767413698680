    import React, { useState, useEffect } from 'react';
    import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
    import { AuthProvider, useAuth } from './AuthContext';
    import LoginPage from './LoginPage';
    import Dashboard from './Dashboard';
    import ForgotPasswordPage from './ForgotPasswordPage';
    import ResetPasswordPage from './ResetPasswordPage';
    import LeagueDetail from './LeagueDetail';
    import DisplayBetDetails from './DisplayBetDetails';
    import TombolaDisplayBetDetails from './TombolaDisplayBetDetails';
    import CreateBetForm from './CreateBetForm';
    import LeagueOutcome from './LeagueOutcome';
    import ConfirmSignUpPage from './ConfirmSignUpPage';
    import CreateLeagueForm from './CreateLeagueForm';
    import ProfilePage from './ProfilePage';
    import SignUpPage from './SignUpPage';
    import UnseenBets from './UnseenBets';
    import Header from './Header';
    import Footer from './Footer';
    import NewPasswordRequired from './NewPasswordRequired';
    import ValidationPopupManager from './ValidationPopupManager';
    import LeagueStats from './LeagueStats';
    import InstallPrompt from './InstallPrompt';
    import MainPage from './MainPage';
    import './App.css';

    const App = () => (
        <AuthProvider>
            <Router>
                <AppContent />
            </Router>
        </AuthProvider>
    );

    const AppContent = () => {
        const location = useLocation();
        const [isSidebarOpen, setIsSidebarOpen] = useState(false);
        const { user, loading, refreshToken } = useAuth();

        useEffect(() => {
            const handleOnline = () => {
                refreshToken();
            };

            window.addEventListener('online', handleOnline);

            return () => {
                window.removeEventListener('online', handleOnline);
            };
        }, [refreshToken]);

        useEffect(() => {
            const refreshInterval = setInterval(refreshToken, 50 * 60 * 1000); // Refresh every 50 minutes

            return () => clearInterval(refreshInterval);
        }, [refreshToken]);

        const closeSidebar = () => {
            setIsSidebarOpen(false);
        };

        if (loading) {
            return <div>Loading...</div>; // Or a loading spinner
        }

        return (
            <div id="outer-container">
                <Header 
                    isSidebarOpen={isSidebarOpen} 
                    setIsSidebarOpen={setIsSidebarOpen} 
                    closeSidebar={closeSidebar} 
                    isLoggedIn={!!user}
                />
                <div className="flex-container">
                    <main id="page-wrap">
                        <InstallPrompt />
                        {/* Add ValidationPopupManager here, only show when user is logged in */}
                        {user && <ValidationPopupManager />}
                        <Routes>
                            <Route path="/mainpage" element={user ? <MainPage /> : <Navigate to="/login" />} />
                            <Route path="/login" element={user ? <Navigate to="/mainpage" /> : <LoginPage />} />
                            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                            <Route path="/reset-password" element={<ResetPasswordPage />} />
                            <Route path="/dashboard" element={user ? <Dashboard closeSidebar={closeSidebar} /> : <Navigate to="/login" />} />
                            <Route path="/sign-up" element={user ? <Navigate to="/mainpage" /> : <SignUpPage />} />
                            <Route path="/leagues/:id" element={user ? <LeagueDetail closeSidebar={closeSidebar} /> : <Navigate to="/login" />} />
                            <Route path="/confirm-signup" element={<ConfirmSignUpPage />} />
                            <Route path="/bets/:id" element={user ? <DisplayBetDetails closeSidebar={closeSidebar} /> : <Navigate to="/login" />} />
                            <Route path="/tombola-bet/:id" element={user ? <TombolaDisplayBetDetails closeSidebar={closeSidebar} /> : <Navigate to="/login" />} />
                            <Route path="/profile" element={user ? <ProfilePage closeSidebar={closeSidebar} /> : <Navigate to="/login" />} />
                            <Route path="/leagues/:id/create-bet" element={user ? <CreateBetForm closeSidebar={closeSidebar} /> : <Navigate to="/login" />} />
                            <Route path="/create-league" element={user ? <CreateLeagueForm closeSidebar={closeSidebar} /> : <Navigate to="/login" />} />
                            <Route path="/leagues/:id/outcome" element={user ? <LeagueOutcome closeSidebar={closeSidebar} /> : <Navigate to="/login" />} />
                            <Route path="/unseen-bets" element={user ? <UnseenBets closeSidebar={closeSidebar} /> : <Navigate to="/login" />} />
                            <Route path="/new-password-required" element={<NewPasswordRequired />} />
                            <Route path="/" element={user ? <Navigate to="/mainpage" /> : <Navigate to="/login" />} />
                            <Route path="/leagues/:id/stats" element={user ? <LeagueStats closeSidebar={closeSidebar} /> : <Navigate to="/login" />} />
                            </Routes>
                    </main>
                    {user && <Footer closeSidebar={closeSidebar} />}
                </div>
            </div>
        );
    };

    if ('serviceWorker' in navigator) {
        window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js')
            .then((registration) => {
            console.log('Service Worker registered successfully:', registration.scope);
            })
            .catch((error) => {
            console.log('Service Worker registration failed:', error);
            });
        });
    }

    export default App;