import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';
import './ConfirmSignUpPage.css';  // Import the CSS file

const poolData = {
    UserPoolId: 'eu-west-3_HluU4UOFJ', // Replace with your User Pool ID
    ClientId: '51i3600jkv5f4cgf5ing1142sg'  // Replace with your App Client ID
};

const userPool = new CognitoUserPool(poolData);

function ConfirmSignUpPage() {
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [isResendDisabled, setIsResendDisabled] = useState(false);
    const [resendTimer, setResendTimer] = useState(60);
    const navigate = useNavigate();

    const handleConfirm = (event) => {
        event.preventDefault();
        const userData = {
            Username: email,
            Pool: userPool
        };
        const cognitoUser = new CognitoUser(userData);

        cognitoUser.confirmRegistration(code, true, (err, result) => {
            if (err) {
                setError(err.message || JSON.stringify(err));
                return;
            }
            setMessage('Email confirmed successfully!');
            setTimeout(() => {
                navigate('/login'); // Redirect to login page after successful confirmation
            }, 3000);
        });
    };

    const handleResendCode = () => {
        const userData = {
            Username: email,
            Pool: userPool
        };
        const cognitoUser = new CognitoUser(userData);

        cognitoUser.resendConfirmationCode((err, result) => {
            if (err) {
                setError(err.message || JSON.stringify(err));
                return;
            }
            setMessage('Verification code resent successfully!');
            setIsResendDisabled(true);
            let countdown = 60;
            const interval = setInterval(() => {
                countdown -= 1;
                setResendTimer(countdown);
                if (countdown <= 0) {
                    clearInterval(interval);
                    setIsResendDisabled(false);
                    setResendTimer(60);
                }
            }, 1000);
        });
    };

    return (
        <div className="confirm-signup-container">
            <h1>Confirm Email</h1>
            <form onSubmit={handleConfirm} className="confirm-signup-form">
                {message && <div className="message">{message}</div>}
                {error && <div className="error-message">{error}</div>}
                <div className="form-group">
                    <label>Email:</label>
                    <input
                        type="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        required
                        autoComplete="email"
                    />
                </div>
                <div className="form-group">
                    <label>Verification Code:</label>
                    <input
                        type="text"
                        value={code}
                        onChange={e => setCode(e.target.value)}
                        required
                        autoComplete="off"
                    />
                </div>
                <button type="submit" className="confirm-button">Confirm</button>
                <button
                    type="button"
                    className="resend-button"
                    onClick={handleResendCode}
                    disabled={isResendDisabled}
                >
                    Resend Code {isResendDisabled && `(${resendTimer})`}
                </button>
            </form>
        </div>
    );
}

export default ConfirmSignUpPage;