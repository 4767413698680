import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';

axios.defaults.withCredentials = true;

const poolData = {
    UserPoolId: 'eu-west-3_HluU4UOFJ',
    ClientId: '51i3600jkv5f4cgf5ing1142sg'
};

const userPool = new CognitoUserPool(poolData);

const AuthContext = createContext({
    user: null,
    loading: true,
    signIn: () => {},
    signOut: () => {},
    refreshToken: () => {},
    completeNewPassword: () => {},
    cognitoSession: null
});

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [cognitoSession, setCognitoSession] = useState(null);

    const checkSession = async () => {
        return new Promise((resolve, reject) => {
            const cognitoUser = userPool.getCurrentUser();
            if (cognitoUser) {
                cognitoUser.getSession((err, session) => {
                    if (err) {
                        reject(err);
                    } else if (session.isValid()) {
                        resolve({
                            email: session.getIdToken().payload.email,
                            token: session.getIdToken().getJwtToken()
                        });
                    } else {
                        reject(new Error("Invalid session"));
                    }
                });
            } else {
                reject(new Error("No current user"));
            }
        });
    };

    const refreshToken = async () => {
        return new Promise((resolve, reject) => {
            const cognitoUser = userPool.getCurrentUser();
            if (cognitoUser) {
                cognitoUser.getSession((err, session) => {
                    if (err) {
                        reject(err);
                        return;
                    }
                    
                    cognitoUser.refreshSession(session.getRefreshToken(), (err, newSession) => {
                        if (err) {
                            console.error("Error refreshing token:", err);
                            signOut();
                            reject(err);
                        } else {
                            const token = newSession.getIdToken().getJwtToken();
                            const newUser = { 
                                email: cognitoUser.getUsername(), 
                                token 
                            };
                            setUser(newUser);
                            localStorage.setItem('authToken', token);
                            resolve(newUser);
                        }
                    });
                });
            } else {
                reject(new Error("No current user"));
            }
        });
    };

    useEffect(() => {
        const initializeAuth = async () => {
            const storedToken = localStorage.getItem('authToken');
            if (storedToken) {
                try {
                    const sessionUser = await checkSession();
                    if (!sessionUser) {
                        await refreshToken();
                    } else {
                        setUser(sessionUser);
                    }
                } catch (error) {
                    console.error("Failed to refresh token:", error);
                    setUser(null);
                    localStorage.removeItem('authToken');
                }
            } else {
                setUser(null);
            }
            setLoading(false);
        };

        initializeAuth();

        const refreshInterval = setInterval(async () => {
            const currentUser = userPool.getCurrentUser();
            if (currentUser) {
                try {
                    await refreshToken();
                } catch (error) {
                    console.error("Failed to refresh token:", error);
                }
            }
        }, 50 * 60 * 1000); // Refresh every 50 minutes

        return () => clearInterval(refreshInterval);
    }, []);

    const signIn = (email, password) => {
        return new Promise((resolve, reject) => {
            const authenticationDetails = new AuthenticationDetails({
                Username: email,
                Password: password,
            });

            const cognitoUser = new CognitoUser({
                Username: email,
                Pool: userPool
            });

            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: (result) => {
                    const token = result.getIdToken().getJwtToken();
                    const newUser = { email, token };
                    setUser(newUser);
                    localStorage.setItem('authToken', token);
                    setCognitoSession(null);
                    resolve(newUser);
                },
                onFailure: (err) => {
                    console.error("Authentication failed:", err);
                    setCognitoSession(null);
                    reject(err);
                },
                newPasswordRequired: (userAttributes, requiredAttributes) => {
                    setCognitoSession({ cognitoUser, userAttributes, requiredAttributes });
                    resolve({
                        challengeName: 'NEW_PASSWORD_REQUIRED',
                        email,
                    });
                }
            });
        });
    };

    const completeNewPassword = (email, newPassword) => {
        return new Promise((resolve, reject) => {
            if (!cognitoSession?.cognitoUser) {
                reject(new Error('No active password change session'));
                return;
            }
    
            const { cognitoUser, userAttributes } = cognitoSession;
    
            // Remove all attributes for the password change challenge
            cognitoUser.completeNewPasswordChallenge(
                newPassword,
                {},  // Pass empty object instead of userAttributes
                {
                    onSuccess: (result) => {
                        const token = result.getIdToken().getJwtToken();
                        const newUser = { email, token };
                        setUser(newUser);
                        localStorage.setItem('authToken', token);
                        setCognitoSession(null);
                        resolve(newUser);
                    },
                    onFailure: (err) => {
                        console.error("Failed to complete password challenge:", err);
                        reject(err);
                    }
                }
            );
        });
    };

    const signOut = () => {
        return new Promise((resolve) => {
            const cognitoUser = userPool.getCurrentUser();
            if (cognitoUser) {
                cognitoUser.signOut(() => {
                    setUser(null);
                    setCognitoSession(null);
                    localStorage.removeItem('authToken');
                    resolve();
                });
            } else {
                setUser(null);
                setCognitoSession(null);
                localStorage.removeItem('authToken');
                resolve();
            }
        });
    };

    useEffect(() => {
        const interceptor = axios.interceptors.response.use(
            response => response,
            async error => {
                if (error.response?.status === 401) {
                    try {
                        await refreshToken();
                        const originalRequest = error.config;
                        const token = localStorage.getItem('authToken');
                        if (token) {
                            originalRequest.headers['Authorization'] = `Bearer ${token}`;
                        }
                        return axios(originalRequest);
                    } catch (refreshError) {
                        await signOut();
                        throw refreshError;
                    }
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axios.interceptors.response.eject(interceptor);
        };
    }, []);

    return (
        <AuthContext.Provider value={{
            user,
            loading,
            signIn,
            signOut,
            refreshToken,
            completeNewPassword,
            cognitoSession
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);

export default AuthContext;