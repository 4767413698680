import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment-timezone';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import './CreateBetForm.css';

axios.defaults.withCredentials = true;

const poolData = {
    UserPoolId: 'eu-west-3_HluU4UOFJ',
    ClientId: '51i3600jkv5f4cgf5ing1142sg'
};
const userPool = new CognitoUserPool(poolData);

const CreateBetForm = () => {
    const { id: leagueId } = useParams();
    const navigate = useNavigate();
    const [email, setEmail] = useState(null);
    const [betDetails, setBetDetails] = useState('');
    const [amount, setAmount] = useState(1);
    const [betType, setBetType] = useState('call only');
    const [deadline, setDeadline] = useState(0);
    const [deadlineType, setDeadlineType] = useState('before');
    const [event, setEvent] = useState('game start');
    const [timezone, setTimezone] = useState(moment.tz.guess());
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showBetTypeDropdown, setShowBetTypeDropdown] = useState(false);
    const [showDeadlineTypeDropdown, setShowDeadlineTypeDropdown] = useState(false);
    const [showTimezoneModal, setShowTimezoneModal] = useState(false);
    const [eventDate, setEventDate] = useState(new Date());
    const [eventTime, setEventTime] = useState('');
    const [creatorOutcome, setCreatorOutcome] = useState('');

    const combineDateTime = () => {
        if (eventDate && eventTime) {
            const [hours, minutes] = eventTime.split(':');
            const combinedDate = new Date(eventDate);
            combinedDate.setHours(hours, minutes);
            return combinedDate.toISOString();
        }
        return '';
    };

    useEffect(() => {
        const checkUserSession = () => {
            const cognitoUser = userPool.getCurrentUser();
            if (cognitoUser) {
                cognitoUser.getSession((err, session) => {
                    if (err || !session.isValid()) {
                        localStorage.setItem('redirectAfterLogin', window.location.pathname);
                        navigate('/login', { replace: true });
                    } else {
                        setEmail(session.idToken.payload.email);
                        const defaultEventDeadline = moment().tz(timezone).add(15, 'minutes');
                        setEventDate(defaultEventDeadline.toDate());
                        setEventTime(defaultEventDeadline.format('HH:mm'));
                        setLoading(false);
                    }
                });
            } else {
                localStorage.setItem('redirectAfterLogin', window.location.pathname);
                navigate('/login', { replace: true });
            }
        };

        checkUserSession();
    }, [navigate, timezone]);

    const validateEventDeadlineTimestamp = (timestamp) => {
        const userTimestamp = moment(timestamp);
        const now = moment();
        const minValidTimestamp = now.add(10, 'minutes');
        return userTimestamp.isAfter(minValidTimestamp);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!email) {
            setError("You must be logged in to create a bet.");
            return;
        }
        
        const combinedDateTime = combineDateTime();
        if (!combinedDateTime) {
            setError("Please set both date and time for the event deadline.");
            return;
        }
        
        if (!validateEventDeadlineTimestamp(combinedDateTime)) {
            setError("The event deadline must be at least 10 minutes from now.");
            return;
        }

        if (betType === 'tombola' && !creatorOutcome.trim()) {
            setError("Please enter your predicted outcome for the tombola bet.");
            return;
        }
        
        try {
            const betData = {
                leagueId,
                email,
                betDetails,
                amount,
                betType,
                deadline,
                deadlineType,
                event,
                event_deadline_timestamp: moment.tz(combinedDateTime, timezone).toISOString(),
                timezone,
                creatorOutcome: betType === 'tombola' ? creatorOutcome.trim() : null
            };

            const response = await axios.post('https://b9s45cfxpe.execute-api.eu-west-3.amazonaws.com/prod/create_bet', betData, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
            });

            // Call the add_to_unseen Lambda function
            await axios.post('https://b9s45cfxpe.execute-api.eu-west-3.amazonaws.com/prod/add_to_unseen', {
                leagueId,
                betId: response.data.betId,
                creatorEmail: email
            }, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
            });

            alert(`Bet Created: ${response.data.message}`);
            navigate(`/bets/${response.data.betId}`);
        } catch (error) {
            console.error('Failed to create bet or add to unseen:', error);
            setError('Failed to create bet or add to unseen list, please try again.');
        }
    };

    if (loading) {
        return <div className="loading">Loading...</div>;
    }

    return (
        <div className="create-bet-form">
            <h1>Create Bet</h1>
            {error && <div className="error-message">{error}</div>}
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="betDetails">Bet Details:</label>
                    <textarea
                        id="betDetails"
                        value={betDetails}
                        onChange={e => setBetDetails(e.target.value)}
                        maxLength={100}
                        placeholder="Enter bet specifics (max 100 characters)"
                        required
                    />
                </div>
    
                <div className="form-group">
                    <label htmlFor="amount">Amount ($):</label>
                    <input
                        type="number"
                        id="amount"
                        value={amount}
                        onChange={e => setAmount(Math.min(Math.max(1, parseInt(e.target.value, 10)), 5))}
                        min="1"
                        max="5"
                        required
                    />
                </div>
    
                <div className="form-group">
                    <label htmlFor="betType">Bet Type:</label>
                    <div className="custom-select">
                        <button 
                            type="button"
                            onClick={() => setShowBetTypeDropdown(!showBetTypeDropdown)}
                            className="select-timezone-btn"
                        >
                            {betType}
                        </button>
                        {showBetTypeDropdown && (
                            <div className="custom-select-options">
                                {['call only', 'money raise allowed', 'tombola'].map(type => (
                                    <div 
                                        key={type} 
                                        className="custom-select-option"
                                        onClick={() => {
                                            setBetType(type);
                                            setShowBetTypeDropdown(false);
                                        }}
                                    >
                                        {type}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>

                {betType === 'tombola' && (
                    <div className="form-group">
                        <label htmlFor="creatorOutcome">Your Predicted Outcome:</label>
                        <input
                            type="text"
                            id="creatorOutcome"
                            value={creatorOutcome}
                            onChange={(e) => setCreatorOutcome(e.target.value)}
                            placeholder="Enter your predicted outcome"
                            required
                        />
                    </div>
                )}
    
                <fieldset className="deadline-box">
                    <legend>Bet Timing</legend>
                    <div className="form-group">
                        <label htmlFor="deadline">Deadline (minutes):</label>
                        <input
                            type="number"
                            id="deadline"
                            value={deadline}
                            onChange={e => setDeadline(e.target.value)}
                            min="0"
                            required
                        />
                    </div>
    
                    <div className="form-group">
                        <label htmlFor="deadlineType">Deadline Type:</label>
                        <div className="custom-select">
                            <button 
                                type="button"
                                onClick={() => setShowDeadlineTypeDropdown(!showDeadlineTypeDropdown)}
                                className="select-timezone-btn"
                            >
                                {deadlineType}
                            </button>
                            {showDeadlineTypeDropdown && (
                                <div className="custom-select-options">
                                    {['before', 'after'].map(type => (
                                        <div 
                                            key={type} 
                                            className="custom-select-option"
                                            onClick={() => {
                                                setDeadlineType(type);
                                                setShowDeadlineTypeDropdown(false);
                                            }}
                                        >
                                            {type}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
    
                    <div className="form-group">
                        <label htmlFor="event">Event:</label>
                        <input
                            type="text"
                            id="event"
                            value={event}
                            onChange={e => setEvent(e.target.value)}
                            placeholder="e.g., game start"
                            required
                        />
                    </div>
    
                    <div className="form-group">
                        <label htmlFor="eventDate">Event Date:</label>
                        <DatePicker
                            selected={eventDate}
                            onChange={(date) => setEventDate(date)}
                            dateFormat="yyyy-MM-dd"
                            minDate={new Date()}
                            className="custom-datepicker"
                            wrapperClassName="datepicker-wrapper"
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="eventTime">Event Time:</label>
                        <input
                            type="time"
                            id="eventTime"
                            value={eventTime}
                            onChange={(e) => setEventTime(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="timezone">Timezone:</label>
                        <div className="custom-select">
                            <button 
                                type="button"
                                onClick={() => setShowTimezoneModal(true)}
                                className="select-timezone-btn"
                            >
                                {timezone} (Click to change)
                            </button>
                            {showTimezoneModal && (
                                <div className="timezone-modal">
                                    <div className="timezone-modal-content">
                                        <h3>Select Timezone</h3>
                                        {moment.tz.names().map(tz => (
                                            <div 
                                                key={tz} 
                                                className="custom-select-option"
                                                onClick={() => {
                                                    setTimezone(tz);
                                                    setShowTimezoneModal(false);
                                                }}
                                            >
                                                {tz}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </fieldset>
    
                <div className="form-actions">
                    <button type="submit" className="btn-primary">Create Bet</button>
                    <button type="button" className="btn-secondary" onClick={() => navigate(`/leagues/${leagueId}`)}>Back</button>
                </div>
            </form>
        </div>
    );
};

export default CreateBetForm;