import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import AWS from 'aws-sdk';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import './CreateLeagueForm.css'; // You'll need to create this CSS file
import  { useAuth } from './AuthContext';
// Configure AWS SDK
AWS.config.update({
  region: 'eu-west-3',
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: 'eu-west-3:d575ab37-87dd-4c02-a78c-2552ef58e96f'
  })
});

// Configure Cognito User Pool
const poolData = {
  UserPoolId: 'eu-west-3_HluU4UOFJ',
  ClientId: '51i3600jkv5f4cgf5ing1142sg'
};
const userPool = new CognitoUserPool(poolData);

const CreateLeagueForm = () => {
    const [leagueName, setLeagueName] = useState('');
    const [description, setDescription] = useState('');
    const [maxPlayers, setMaxPlayers] = useState('');
    const [maxBet, setMaxBet] = useState('');
    const [playerBudgetPerMonth, setPlayerBudgetPerMonth] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const stored_email = localStorage.getItem('email');
    const { user} = useAuth();
    let currentUserEmail;  // Declare currentUserEmail outside the if block

    if (!stored_email) {
        currentUserEmail = user?.email;
    } else {
        currentUserEmail = stored_email;
    }

    const handleCreateLeagueSubmit = (e) => {
        e.preventDefault();
        let isAdjusted = false;
        let errorMessages = [];
        let adjustedMaxPlayers = parseInt(maxPlayers);
        let adjustedMaxBet = parseInt(maxBet);
        let adjustedPlayerBudgetPerMonth = parseInt(playerBudgetPerMonth);
    
        // Validate maxPlayers
        if (isNaN(adjustedMaxPlayers) || adjustedMaxPlayers < 2) {
            adjustedMaxPlayers = 2;
            errorMessages.push("Max players adjusted to minimum (2)");
            isAdjusted = true;
        } else if (adjustedMaxPlayers > 10) {
            adjustedMaxPlayers = 10;
            errorMessages.push("Max players adjusted to maximum (10)");
            isAdjusted = true;
        }
    
        // Validate maxBet
        if (isNaN(adjustedMaxBet) || adjustedMaxBet < 5) {
            adjustedMaxBet = 5;
            errorMessages.push("Maximum bet amount adjusted to minimum ($5)");
            isAdjusted = true;
        } else if (adjustedMaxBet > 10) {
            adjustedMaxBet = 10;
            errorMessages.push("Maximum bet amount adjusted to maximum ($10)");
            isAdjusted = true;
        }
    
        // Validate playerBudgetPerMonth
        if (isNaN(adjustedPlayerBudgetPerMonth) || adjustedPlayerBudgetPerMonth < 10) {
            adjustedPlayerBudgetPerMonth = 10;
            errorMessages.push("Player budget per month adjusted to minimum ($10)");
            isAdjusted = true;
        } else if (adjustedPlayerBudgetPerMonth > 40) {
            adjustedPlayerBudgetPerMonth = 40;
            errorMessages.push("Player budget per month adjusted to maximum ($40)");
            isAdjusted = true;
        }
    
        // Check if league name and description are not empty
        if (!leagueName.trim()) {
            errorMessages.push("League name is required");
            isAdjusted = true;
        }
        if (!description.trim()) {
            errorMessages.push("Description is required");
            isAdjusted = true;
        }
    
        if (isAdjusted) {
            // Update state with adjusted values
            setMaxPlayers(adjustedMaxPlayers);
            setMaxBet(adjustedMaxBet);
            setPlayerBudgetPerMonth(adjustedPlayerBudgetPerMonth);
            setErrorMessage(errorMessages.join(". "));
            return; // Don't proceed with league creation
        }
    
        // If we reach here, all inputs are valid and within range
        setErrorMessage(""); // Clear any previous error messages
    
        // Proceed with league creation
        try {
            const cognitoUser = userPool.getCurrentUser();
            if (cognitoUser) {
                cognitoUser.getSession((err, session) => {
                    if (err || !session.isValid()) {
                        navigate('/login', { replace: true });
                    } else {
                        axios.post('https://b9s45cfxpe.execute-api.eu-west-3.amazonaws.com/prod/create_league', {
                            leagueName,
                            email : currentUserEmail,
                            description,
                            maxPlayers: adjustedMaxPlayers,
                            maxBet: adjustedMaxBet,
                            playerBudgetPerMonth: adjustedPlayerBudgetPerMonth
                        }, {
                            headers: { 'Authorization': `Bearer ${token}` }
                        }).then(response => {
                            navigate('/dashboard', { state: { message: 'League created successfully' } });
                        }).catch(error => {
                            console.error('Failed to create league:', error);
                            setErrorMessage('Failed to create league');
                        });
                    }
                });
            } else {
                navigate('/login', { replace: true });
            }
        } catch (error) {
            console.error('Failed to create league:', error);
            setErrorMessage('Failed to create league');
        }
    };

    return (
        <div className="create-league-container">
            <h2>Create a League</h2>
            <form onSubmit={handleCreateLeagueSubmit}>
                <div className="form-group">
                    <label htmlFor="leagueName">League Name:</label>
                    <input
                        type="text"
                        id="leagueName"
                        value={leagueName}
                        onChange={(e) => setLeagueName(e.target.value)}
                        placeholder="Enter League Name"
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="description">Description:</label>
                    <textarea
                        id="description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Enter Description"
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="maxPlayers">Max Players (2-10):</label>
                    <input
                        type="number"
                        id="maxPlayers"
                        value={maxPlayers}
                        onChange={(e) => setMaxPlayers(e.target.value)}
                        placeholder="Enter Max Players"
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="maxBet">Maximum Bet Amount ($5-$10):</label>
                    <input
                        type="number"
                        id="maxBet"
                        value={maxBet}
                        onChange={(e) => setMaxBet(e.target.value)}
                        placeholder="Enter Maximum Bet Amount"
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="playerBudgetPerMonth">Player Budget Per Month ($10-$40):</label>
                    <input
                        type="number"
                        id="playerBudgetPerMonth"
                        value={playerBudgetPerMonth}
                        onChange={(e) => setPlayerBudgetPerMonth(e.target.value)}
                        placeholder="Enter Player Budget Per Month"
                        required
                    />
                </div>
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                <div className="form-buttons">
                    <button type="submit" className="create-button">Create League</button>
                    <button type="button" className="cancel-button" onClick={() => navigate('/dashboard')}>Cancel</button>
                </div>
            </form>
        </div>
    );
};

export default CreateLeagueForm;