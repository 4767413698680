import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import { isValid, parse } from 'date-fns';
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';
import './SignUpPage.css';
import TimezoneSelector from './TimezoneSelector'; 

function SignUpPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [birthdate, setBirthdate] = useState('');
    const [preferredUsername, setPreferredUsername] = useState('');
    const [nickName, setNickName] = useState('');
    const [timezone, setTimezone] = useState(null);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };

    const handleBirthdateChange = (date) => {
        if (date instanceof Date && !isNaN(date)) {
            setBirthdate(date);
        } else if (typeof date === 'string') {
            const parsedDate = parse(date, 'dd-MM-yyyy', new Date());
            if (isValid(parsedDate)) {
                setBirthdate(parsedDate);
            } else {
                setError('Invalid date format. Please use DD-MM-YYYY.');
            }
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        if (!birthdate || !isValid(new Date(birthdate))) {
            setError('Please enter a valid birthdate');
            return;
        }

        const userData = {
            email,
            password,
            birthdate: birthdate instanceof Date ? birthdate.toLocaleDateString('en-GB') : birthdate,
            preferredUsername,
            nickName,
            timezone: timezone ? timezone.value : ''
        };

        try {
            const response = await axios.post('https://b9s45cfxpe.execute-api.eu-west-3.amazonaws.com/prod/register', userData);
            if (response.status === 201) {
                setSuccess('Sign up successful! Please check your email for verification.');
                setTimeout(() => {
                    navigate('/confirm-signup', { state: { email } });
                }, 3000);
            }
        } catch (err) {
            setError(err.response?.data?.error || 'An error occurred during registration.');
        }
    };

    return (
        <div className="sign-up-container">
            <div className="sign-up-card">
                <h1>Sign Up</h1>
                <form onSubmit={handleSubmit} className="sign-up-form">
                    {error && <div className="error-message">{error}</div>}
                    {success && <div className="success-message">{success}</div>}
                    
                    <div className="form-group">
                        <label htmlFor="email">Email:</label>
                        <input
                            id="email"
                            type="email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            required
                            autoComplete="email"
                        />
                    </div>
    
                    <div className="form-group">
                        <label htmlFor="password">Password:</label>
                        <input
                            id="password"
                            type="password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            required
                            autoComplete="new-password"
                        />
                    </div>
    
                    <div className="form-group">
                        <label htmlFor="confirmPassword">Confirm Password:</label>
                        <input
                            id="confirmPassword"
                            type="password"
                            value={confirmPassword}
                            onChange={e => setConfirmPassword(e.target.value)}
                            required
                            autoComplete="new-password"
                        />
                    </div>
    
                    <div className="form-group">
                        <label htmlFor="birthdate">Birthdate:</label>
                        <DatePicker
                            id="birthdate"
                            selected={birthdate}
                            onChange={handleBirthdateChange}
                            dateFormat="dd-MM-yyyy"
                            placeholderText="DD-MM-YYYY"
                            customInput={
                                <input
                                    type="text"
                                    value={birthdate instanceof Date ? birthdate.toLocaleDateString('en-GB') : birthdate}
                                    onChange={(e) => handleBirthdateChange(e.target.value)}
                                />
                            }
                        />
                    </div>
    
                    <div className="form-group">
                        <label htmlFor="preferredUsername">Preferred Username:</label>
                        <input
                            id="preferredUsername"
                            type="text"
                            value={preferredUsername}
                            onChange={e => setPreferredUsername(e.target.value)}
                            required
                        />
                    </div>
    
                    <div className="form-group">
                        <label htmlFor="nickName">Nickname:</label>
                        <input
                            id="nickName"
                            type="text"
                            value={nickName}
                            onChange={e => setNickName(e.target.value)}
                            required
                        />
                    </div>
    
                    <div className="form-group">
                        <label htmlFor="timezone">Timezone by capital:</label>
                        <TimezoneSelector
                            value={timezone}
                            onChange={(newValue) => setTimezone(newValue)}
                        />
                    </div>
    
                    <button type="submit" className="sign-up-button">Sign Up</button> 
                    <button type="button" onClick={handleBack} className="sign-up-button">← Cancel</button>
                </form>
            </div>
        </div>
    );
}

export default SignUpPage;