import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment-timezone';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import AWS from 'aws-sdk';
import './BetDetail.css';
import Modal from 'react-modal';
import { useAuth } from './AuthContext';
import { FaArrowLeft, FaCheck, FaTimes, FaShare, FaEllipsisH } from 'react-icons/fa';

const ParticipantItem = ({ username, email, closeRequests, validatedBy, challengedBy }) => {
    console.log('ParticipantItem data:', { username, email, closeRequests, validatedBy, challengedBy });
    const closeRequest = closeRequests.find(cr => cr.user_id === email);
    
    const parseAndCheck = (item, email) => {
        if (typeof item === 'string') {
            try {
                const jsonString = item.replace(/'/g, '"');
                const parsed = JSON.parse(jsonString);
                return parsed.user_id === email;
            } catch (error) {
                console.error('Error parsing data:', error);
                console.log('Problematic data:', item);
                return false;
            }
        }
        return item.user_id === email;
    };

    const isValidated = Array.isArray(validatedBy) 
        ? validatedBy.some(v => parseAndCheck(v, email))
        : false;
    
    const isChallenged = Array.isArray(challengedBy)
        ? challengedBy.some(c => parseAndCheck(c, email))
        : false;

    return (
        <li key={email}>
            {username}
            {closeRequest && (
                <span className="outcome-info"> submitted outcome: {closeRequest.outcome} wins</span>
            )}
            {isValidated && (
                <span className="outcome-info validated">validated outcome</span>
            )}
            {isChallenged && (
                <span className="outcome-info challenged">challenged outcome</span>
            )}
        </li>
    );
};

// Configure AWS SDK and Cognito User Pool
AWS.config.update({
  region: 'eu-west-3',
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: 'eu-west-3:d575ab37-87dd-4c02-a78c-2552ef58e96f'
  })
});

const poolData = {
  UserPoolId: 'eu-west-3_HluU4UOFJ',
  ClientId: '51i3600jkv5f4cgf5ing1142sg'
};
const userPool = new CognitoUserPool(poolData);

axios.defaults.withCredentials = true;

const DisplayBetDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [betData, setBetData] = useState(null);
    const [creatorUsername, setCreatorUsername] = useState(null);
    const [betForUsernames, setBetForUsernames] = useState([]);
    const [betAgainstUsernames, setBetAgainstUsernames] = useState([]);
    const [raiseAmount, setRaiseAmount] = useState(1);
    const [userInBetFor, setUserInBetFor] = useState(false);
    const [userInBetAgainst, setUserInBetAgainst] = useState(false);
    const [maxBet, setMaxBet] = useState(5);
    const [showModal, setShowModal] = useState(false);
    const [selectedOutcome, setSelectedOutcome] = useState('');
    const [existingOutcome, setExistingOutcome] = useState(null);
    const [closeRequests, setCloseRequests] = useState([]);
    const [validatedBy, setValidatedBy] = useState([]);
    const [challengedBy, setChallengedBy] = useState([]);
    const [allUsersResponded, setAllUsersResponded] = useState(false);
    const [showCloseRequestError, setShowCloseRequestError] = useState(false);

    const token = localStorage.getItem('token');
    const { user } = useAuth();
    const email = user?.email || localStorage.getItem('email');

    const fetchBetDetails = async () => {
        setLoading(true);
        try {
            const response = await axios.post('https://b9s45cfxpe.execute-api.eu-west-3.amazonaws.com/prod/get_bet_details', 
                { betId: id, email },
                { headers: { 'Authorization': `Bearer ${token}` } }
            );
            if (response.status === 200) {
                const betData = response.data;
                
                if (betData.betData.betType === 'tombola') {
                    // Redirect to TombolaDisplayBetDetails
                    navigate(`/tombola-bet/${id}`);
                    return; // Exit the function early
                }


                setBetData(betData);
                setRaiseAmount(betData.amount + 1);
                setCreatorUsername(betData.betData.nickname);
                
                const emailToUsername = {};
                betData.betForUsernames.forEach((username, index) => {
                    emailToUsername[betData.betData.bet_for[index]] = username;
                });
                betData.betAgainstUsernames.forEach((username, index) => {
                    emailToUsername[betData.betData.bet_against[index]] = username;
                });
                
                setBetForUsernames(betData.betData.bet_for.map(email => emailToUsername[email]));
                setBetAgainstUsernames(betData.betData.bet_against.map(email => emailToUsername[email]));
            } else {
                throw new Error('Failed to fetch bet details');
            }
        } catch (error) {
            console.error('Failed to fetch bet details:', error);
            setError('Failed to fetch bet details');
        } finally {
            setLoading(false);
        }
    };

    const fetchCloseRequests = async () => {
        try {
            const response = await axios.post('https://b9s45cfxpe.execute-api.eu-west-3.amazonaws.com/prod/get_close_requests', {
                betId: id,
                email
            }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
    
            if (response.status === 200) {
                const requests = response.data.close_requests || [];
                setCloseRequests(Array.isArray(requests) ? requests : []);
                
                const parseValidationData = (data) => {
                    if (Array.isArray(data)) return data;
                    if (typeof data === 'string') {
                        try {
                            const jsonString = data.replace(/'/g, '"');
                            const parsed = JSON.parse(jsonString);
                            return Array.isArray(parsed) ? parsed : [parsed];
                        } catch (error) {
                            console.error('Error handling validation data:', error);
                            return [];
                        }
                    }
                    return [data].filter(Boolean);
                };
    
                console.log('Raw validated_by:', response.data.validated_by);
                console.log('Raw challenged_by:', response.data.challenged_by);
    
                setValidatedBy(parseValidationData(response.data.validated_by));
                setChallengedBy(parseValidationData(response.data.challenged_by));
                
                const userCloseRequest = requests.find(cr => cr.user_id === email);
                if (userCloseRequest) {
                    setExistingOutcome(userCloseRequest.outcome);
                }
                checkAllUsersResponded(requests, response.data.validated_by);
            }
        } catch (error) {
            console.error('Failed to fetch close requests:', error);
        }
    };

    const checkAllUsersResponded = (closeRequests, validatedBy) => {
        setAllUsersResponded(betData.betData.bet_validated);
    };

    useEffect(() => {
        const initialize = async () => {
            await fetchBetDetails();
        };
        initialize();
    }, []);

    useEffect(() => {
        if (betData) {
            setUserInBetFor(betData.betData.bet_for.includes(email));
            setUserInBetAgainst(betData.betData.bet_against?.includes(email));
            fetchCloseRequests();
        }
    }, [betData]);

    const isUserParticipant = betData && (betData.betData.bet_for.includes(email) || betData.betData.bet_against.includes(email));

    const handleJoinBet = async (type) => {
        if (type === 'raise' && (raiseAmount < betData.amount + 1 || raiseAmount > maxBet)) {
            alert(`Raise amount must be between ${betData.amount + 1} and ${maxBet}.`);
            return;
        }

        try {
            const response = await axios.post('https://b9s45cfxpe.execute-api.eu-west-3.amazonaws.com/prod/join_bet', {
                betId: id,
                email,
                join_type: type,
                amount: type === 'raise' ? raiseAmount : null
            }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });

            if (response.status === 200) {
                alert('Successfully joined the bet');
                window.location.reload();
            } else {
                alert('Failed to join the bet');
            }
        } catch (error) {
            console.error('Failed to join the bet:', error);
            alert('Failed to join the bet, please try again.');
        }
    };

    const handleCloseBet = async () => {
        try {
            const response = await axios.post('https://b9s45cfxpe.execute-api.eu-west-3.amazonaws.com/prod/get_close_requests', {
                betId: id,
                email
            }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });

            if (response.status === 200 && Array.isArray(response.data.close_requests) && response.data.close_requests.length > 0) {
                setShowCloseRequestError(true);
                return;
            }

            const closeResponse = await axios.post('https://b9s45cfxpe.execute-api.eu-west-3.amazonaws.com/prod/close_bet', {
                betId: id,
                email,
                outcome: selectedOutcome,
                userId: email
            }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });

            if (closeResponse.status === 200) {
                alert('Bet close request submitted. Waiting for other players to validate.');
                setShowModal(false);
                setExistingOutcome(selectedOutcome);
                navigate(0);
            } else {
                alert('Failed to submit close request.');
            }
        } catch (error) {
            console.error('Failed to close the bet:', error);
            alert('Failed to close the bet, please try again.');
        }
    };

    
    const generateShareText = (bet) => {
        const eventDeadline = moment.utc(betData.betData.event_deadline_timestamp).tz(moment.tz.guess());
        let deadlineTime;
        if (betData.betData.deadlineType === 'before') {
            deadlineTime = eventDeadline.clone().subtract(betData.betData.deadline, 'minutes');
        } else {
            deadlineTime = eventDeadline.clone().add(betData.betData.deadline, 'minutes');
        }
        const now = moment();
        let deadline_text;
        if (now.isAfter(deadlineTime)) {
            deadline_text = `Deadline passed ${deadlineTime.from(now)}`;
        } else {
            deadline_text = `Deadline in ${deadlineTime.fromNow(true)}`;
        }
        return `Check out this bet!
        Details: ${betData.betData.betDetails}
        Amount: $${betData.betData.amount}
        Type: ${betData.betData.betType}
        Deadline: ${deadline_text} at ${eventDeadline}
        Join here: ${window.location.href}`;
    };

    const copyToClipboard = (text) => {
        if (navigator.clipboard && window.isSecureContext) {
            // navigator.clipboard is available
            navigator.clipboard.writeText(text)
                .then(() => {
                    alert('Bet details copied to clipboard!');
                })
                .catch((err) => {
                    console.error('Failed to copy: ', err);
                    fallbackCopyTextToClipboard(text);
                });
        } else {
            // navigator.clipboard is not available
            fallbackCopyTextToClipboard(text);
        }
    };
    
    const fallbackCopyTextToClipboard = (text) => {
        const textArea = document.createElement("textarea");
        textArea.value = text;
        
        // Make the textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
    
        try {
            const successful = document.execCommand('copy');
            const msg = successful ? 'Bet details copied to clipboard!' : 'Unable to copy bet details';
            alert(msg);
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
            alert('Failed to copy bet details. Please try again.');
        }
    
        document.body.removeChild(textArea);
    };
    
    const handleShareBet = () => {
        if (betData) {
            const shareText = generateShareText(betData);
            copyToClipboard(shareText);
        }
    };

    const handleCancelCloseRequest = async () => {
        try {
            const response = await axios.post('https://b9s45cfxpe.execute-api.eu-west-3.amazonaws.com/prod/cancel_close_request', {
                betId: id,
                email,
                userId: email
            }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });

            if (response.status === 200) {
                alert('Close request canceled.');
                setExistingOutcome(null);
                navigate(0);
            } else {
                alert('Failed to cancel close request.');
            }
        } catch (error) {
            console.error('Failed to cancel close request:', error);
            alert('Failed to cancel close request, please try again.');
        }
    };

    const handleValidateClose = async (accept) => {
        try {
            const response = await axios.post('https://b9s45cfxpe.execute-api.eu-west-3.amazonaws.com/prod/validate_close_bet', {
                betId: id,
                email,
                accept: accept
            }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });

            if (response.status === 200) {
                alert('Validation recorded.');
                await fetchCloseRequests();
            } else {
                alert('Failed to validate close request.');
            }
        } catch (error) {
            console.error('Failed to validate close request:', error);
            alert('Failed to validate close request, please try again.');
        }
    };

    const handleClearOutcome = async () => {
        try {
            const response = await axios.post('https://b9s45cfxpe.execute-api.eu-west-3.amazonaws.com/prod/clear_outcome', {
                betId: id,
                email
            }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            if (response.status === 200) {
                alert('Outcome cleared. You can now submit a new outcome.');
                navigate(0);
            } else if (response.status === 400 && response.data.message === 'Someone already cleared the outcome. Please refresh the page.') {
                alert('Someone already cleared the outcome. Please refresh the page.');
            } else {
                alert('Failed to clear outcome.');
            }
        } catch (error) {
            console.error('Failed to clear outcome:', error);
            alert('Failed to clear outcome, please try again.');
        }
    };

    const hasDeadlinePassed = () => {
        const eventDeadline = moment.utc(betData.betData.event_deadline_timestamp).tz(moment.tz.guess());
        let deadlineTime;
        if (betData.betData.deadlineType === 'before') {
            deadlineTime = eventDeadline.clone().subtract(betData.betData.deadline, 'minutes');
        } else {
            deadlineTime = eventDeadline.clone().add(betData.betData.deadline, 'minutes');
        }
        return moment().isAfter(deadlineTime);
    };

    const getDeadlineStatus = () => {
        const eventDeadline = moment.utc(betData.betData.event_deadline_timestamp).tz(moment.tz.guess());
        let deadlineTime;
        if (betData.betData.deadlineType === 'before') {
            deadlineTime = eventDeadline.clone().subtract(betData.betData.deadline, 'minutes');
        } else {
            deadlineTime = eventDeadline.clone().add(betData.betData.deadline, 'minutes');
        }
        const now = moment();
        if (now.isAfter(deadlineTime)) {
            return `Deadline passed ${deadlineTime.from(now)}`;
        } else {
            return `Deadline in ${deadlineTime.fromNow(true)}`;
        }
    };

    const hasAnyOutcomeSubmitted = Array.isArray(closeRequests) && closeRequests.length > 0;
    const isUserSubmitter = (Array.isArray(closeRequests) && closeRequests.some(cr => cr.user_id === email));
    const hasUserValidated = (Array.isArray(validatedBy) && validatedBy.includes(email)) || validatedBy.toString().includes(email);
    const hasUserChallenged = (Array.isArray(challengedBy) && challengedBy.includes(email)) || challengedBy.toString().includes(email);

    const shouldShowCloseBetButton = betForUsernames.length > 0 && betAgainstUsernames.length > 0 && !hasAnyOutcomeSubmitted && hasDeadlinePassed();

    if (loading) {
        return (
            <div className="loading-container">
                <div className="loading-spinner"></div>
                <p>Loading bet details...</p>
            </div>
        );
    }

    if (error) {
        return (
            <div className="error-container">
                <p>{error}</p>
            </div>
        );
    }

    if (!betData) {
        return <p>Failed to load bet details. Please try again later.</p>;
    }

    return (
        <div className="bet-detail-container">
            <div className="bet-detail-card">
                <h1 className="bet-title">Bet Details</h1>
                <div className="bet-info">
                    <p><span className="icon-user"></span> Created by: {creatorUsername}</p>
                    <p><span className="icon-info"></span> {betData.betData.betDetails}</p>
                    <p><span className="icon-dollar"></span> Amount: ${betData.betData.amount}</p>
                    <p><span className="icon-type"></span> Type: {betData.betData.betType}</p>
                    <p><span className="icon-clock"></span> Deadline: {getDeadlineStatus()}</p>
                    <p><span className="icon-calendar"></span> Event Deadline: {moment.utc(betData.betData.event_deadline_timestamp).tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm:ss')}</p>
                </div>
                <button className="share-button" onClick={handleShareBet}>
                    <FaShare /> Share Bet
                </button>
            </div>

            <div className="participants-container">
                <div className="participants-card betting-for">
                    <h3><span className="icon-users"></span> Users Betting For</h3>
                    {betForUsernames && betForUsernames.length > 0 ? (
                        <ul className="participants-list">
                            {betData.betData.bet_for.map((email, index) => (
                                <ParticipantItem 
                                    key={email}
                                    username={betForUsernames[index]}
                                    email={email}
                                    closeRequests={closeRequests}
                                    validatedBy={validatedBy}
                                    challengedBy={challengedBy}
                                />
                            ))}
                        </ul>
                    ) : <p className="no-participants">No users betting for.</p>}
                </div>

                <div className="participants-card betting-against">
                    <h3><span className="icon-users"></span> Users Betting Against</h3>
                    {betAgainstUsernames && betAgainstUsernames.length > 0 ? (
                        <ul className="participants-list">
                            {betData.betData.bet_against.map((email, index) => (
                                <ParticipantItem 
                                    key={email}
                                    username={betAgainstUsernames[index]}
                                    email={email}
                                    closeRequests={closeRequests}
                                    validatedBy={validatedBy}
                                    challengedBy={challengedBy}
                                />
                            ))}
                        </ul>
                    ) : <p className="no-participants">No users betting against.</p>}
                </div>
            </div>

            <div className="bet-actions">
                {!(userInBetFor || userInBetAgainst) && !hasDeadlinePassed() && (
                    <div className="join-bet-buttons">
                        {betData.betType === 'money raise allowed' && (
                            <div className="raise-bet">
                                <input
                                    type="number"
                                    value={raiseAmount}
                                    onChange={(e) => setRaiseAmount(Math.min(Math.max(betData.amount + 1, parseInt(e.target.value, 10)), maxBet))}
                                    min={betData.amount + 1}
                                    max={maxBet}
                                />
                                <button className="btn-raise" onClick={() => handleJoinBet('raise')}>Raise Bet</button>
                            </div>
                        )}
                        <button className="btn-against" onClick={() => handleJoinBet('against')}>Bet Against</button>
                    </div>
                )}

                {isUserParticipant && (userInBetFor || userInBetAgainst) && shouldShowCloseBetButton && (
                    <button className="btn-close" onClick={() => setShowModal(true)}>
                        {existingOutcome ? 'Change Outcome' : 'Close Bet'}
                    </button>
                )}

                {!hasDeadlinePassed() && (
                    <p className="deadline-message">You cannot close the bet until the deadline has passed.</p>
                )}

                {isUserParticipant && !isUserSubmitter && hasAnyOutcomeSubmitted && !hasUserValidated && !hasUserChallenged && (
                    <div className="outcome-validation">
                        <p>Another user has submitted a close request. Do you accept this outcome?</p>
                        <button className="btn-accept" onClick={() => handleValidateClose(true)}>Accept Outcome</button>
                        <button className="btn-challenge" onClick={() => handleValidateClose(false)}>Challenge Outcome</button>
                    </div>
                )}

                {isUserParticipant && isUserSubmitter && !allUsersResponded && (
                    <div className="submitter-waiting">
                        <p>You have submitted a close request. Waiting for other players to validate.</p>
                        <button className="btn-cancel" onClick={handleCancelCloseRequest}>Cancel Close Request</button>
                    </div>
                )}

                {isUserParticipant && hasUserValidated && !allUsersResponded && (
                    <p className="status-message">You have already validated the close request.</p>
                )}

                {isUserParticipant && hasUserChallenged && (
                    <p className="status-message">You have already challenged the close request.</p>
                )}

                {isUserParticipant && allUsersResponded && (
                    <p className="status-message">All users have responded to the bet outcome.</p>
                )}

                {isUserParticipant && challengedBy.length > 0 && (
                    <div className="challenge-resolution">
                        <p>One or more users have challenged the outcome. Discuss and agree on an outcome.</p>
                        <button className="btn-clear" onClick={handleClearOutcome}>Clear Outcome</button>
                    </div>
                )}
            </div>

            <div className="navigation-buttons">
                <button className="btn-back" onClick={() => navigate(`/leagues/${betData.betData.leagueId}`)}>Back to League</button>
                <button className="btn-refresh" onClick={() => navigate(0)}>Refresh</button>
            </div>

            <Modal 
                isOpen={showModal} 
                onRequestClose={() => setShowModal(false)}
                className="modal"
                overlayClassName="modal-overlay"
            >
                <div className="modal-content">
                    <h3>Select Outcome</h3>
                    <div className="outcome-selection">
                        <label>
                            <input
                                type="radio"
                                value="for"
                                checked={selectedOutcome === 'for'}
                                onChange={() => setSelectedOutcome('for')}
                            />
                            Bet For Won
                        </label>
                        <label>
                            <input
                                type="radio"
                                value="against"
                                checked={selectedOutcome === 'against'}
                                onChange={() => setSelectedOutcome('against')}
                            />
                            Bet Against Won
                        </label>
                    </div>
                    <div className="modal-buttons">
                        <button className="btn-submit" onClick={handleCloseBet} disabled={!selectedOutcome}>Submit</button>
                        <button className="btn-cancel" onClick={() => setShowModal(false)}>Cancel</button>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={showCloseRequestError}
                onRequestClose={() => setShowCloseRequestError(false)}
                className="modal"
                overlayClassName="modal-overlay"
            >
                <div className="modal-content">
                    <p>Another user has already submitted a close request. Please refresh the page to see the updated status.</p>
                    <button className="btn-ok" onClick={() => navigate(0)}>OK</button>
                </div>
            </Modal>
        </div>
    );
};

export default DisplayBetDetails;