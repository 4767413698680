import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';
import './ResetPasswordPage.css';  // Import the CSS file

const poolData = {
    UserPoolId: 'eu-west-3_HluU4UOFJ', // Replace with your User Pool ID
    ClientId: '51i3600jkv5f4cgf5ing1142sg' // Replace with your App Client ID
};

const userPool = new CognitoUserPool(poolData);

function ResetPasswordPage() {
    const location = useLocation();
    const email = location.state.email;  // Get email from navigation state
    const [code, setCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');  // Add confirm password state
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const handleBack = () => {
        navigate(-1); // This navigates to the previous page
    };
    const handleSubmit = (event) => {
        event.preventDefault();

        if (newPassword !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        const cognitoUser = new CognitoUser({
            Username: email,
            Pool: userPool
        });

        cognitoUser.confirmPassword(code, newPassword, {
            onSuccess: () => {
                setMessage('Password reset successful.');
                navigate('/login');
            },
            onFailure: (err) => {
                setError(err.message || JSON.stringify(err));
            }
        });
    };

    return (
        <div className="reset-password-container">
            <h1>Reset Password</h1>
            <form onSubmit={handleSubmit} className="reset-password-form">
                {error && <div className="error-message">{error}</div>} {/* Display error message */}
                {message && <div className="success-message">{message}</div>} {/* Display success message */}
                <div className="form-group">
                    <label>Verification Code:</label>
                    <input
                        type="text"
                        value={code}
                        onChange={e => setCode(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>New Password:</label>
                    <input
                        type="password"
                        value={newPassword}
                        onChange={e => setNewPassword(e.target.value)}
                        required
                        autoComplete="new-password"
                    />
                </div>
                <div className="form-group">
                    <label>Confirm Password:</label>
                    <input
                        type="password"
                        value={confirmPassword}
                        onChange={e => setConfirmPassword(e.target.value)}
                        required
                        autoComplete="new-password"
                    />
                </div>
                <button type="submit" className="reset-password-button">Reset Password</button>
                <div className="or-separator">OR</div>
                <button onClick={handleBack} className="reset-password">← Back</button>
            </form>
        </div>
    );
}

export default ResetPasswordPage;
