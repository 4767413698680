import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { 
    FaChevronDown, 
    FaTrophy, 
    FaArrowRight,
    FaChartLine, 
    FaUsers, 
    FaDollarSign, 
    FaFire, 
    FaBolt, 
    FaCalendarAlt, 
    FaUserFriends,
    FaGamepad,
    FaPercentage,
    FaClock,
    FaSkull
} from 'react-icons/fa';
import './MainPage.css';

const MainPage = () => {
    const [leagues, setLeagues] = useState([]);
    const [expandedLeagues, setExpandedLeagues] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [leagueStats, setLeagueStats] = useState({});
    const navigate = useNavigate();
    const { user } = useAuth();
    const token = localStorage.getItem('token');
    const email = user?.email || localStorage.getItem('email');

    useEffect(() => {
        fetchLeagues();
    }, []);

    const fetchLeagues = async () => {
        try {
            const response = await axios.post(
                'https://b9s45cfxpe.execute-api.eu-west-3.amazonaws.com/prod/get_leagues',
                { email },
                { headers: { 'Authorization': `Bearer ${token}` } }
            );
    
            if (response.status === 200) {
                setLeagues(response.data.leagues);
                await Promise.all(response.data.leagues.map(league => fetchLeagueStatistics(league.id)));
            }
        } catch (error) {
            console.error('Failed to fetch leagues:', error);
            setError('Failed to fetch leagues');
        } finally {
            setLoading(false);
        }
    };

    const fetchLeagueStatistics = async (leagueId) => {
        try {
            const response = await axios.post(
                'https://b9s45cfxpe.execute-api.eu-west-3.amazonaws.com/prod/league_user_statistics',
                { 
                    league_id: leagueId,
                    email 
                },
                { headers: { 'Authorization': `Bearer ${token}` } }
            );

            if (response.status === 200) {
                setLeagueStats(prev => ({
                    ...prev,
                    [leagueId]: response.data.statistics
                }));
            }
        } catch (error) {
            console.error(`Failed to fetch statistics for league ${leagueId}:`, error);
        }
    };

    const toggleLeague = (leagueId) => {
        setExpandedLeagues(prev => ({
            ...prev,
            [leagueId]: !prev[leagueId]
        }));
    };

    const handleGoToLeague = (leagueId, event) => {
        event.stopPropagation(); // Prevent the league card from toggling when clicking the button
        navigate(`/leagues/${leagueId}`);
    };

    const getTimeAgo = (timestamp) => {
        if (!timestamp) return 'Never';
        const now = new Date();
        const date = new Date(timestamp);
        const seconds = Math.floor((now - date) / 1000);
        
        if (seconds < 60) return 'Just now';
        if (seconds < 3600) return `${Math.floor(seconds / 60)}m ago`;
        if (seconds < 86400) return `${Math.floor(seconds / 3600)}h ago`;
        return `${Math.floor(seconds / 86400)}d ago`;
    };

    if (loading) {
        return (
            <div className="loading-container">
                <div className="loading-spinner"></div>
                <p>Loading statistics...</p>
            </div>
        );
    }

    if (error) {
        return <div className="error-message">{error}</div>;
    }

    return (
        <div className="main-page-container">
            <div className="main-page-header">
                <h1>Gaming Statistics</h1>
            </div>
            <div className="leagues-grid">
                {leagues.map(league => (
                    <div key={league.id} className="league-card">
<div className="league-header" onClick={() => toggleLeague(league.id)}>
                            <h2>{league.name}</h2>
                            <div className="league-header-actions">
                                <button 
                                    className="go-to-league-button" 
                                    onClick={(e) => handleGoToLeague(league.id, e)}
                                >
                                    Go <FaArrowRight className="button-icon" />
                                </button>
                                <FaChevronDown className={`league-expand-icon ${expandedLeagues[league.id] ? 'expanded' : ''}`} />
                            </div>
                        </div>
                        <div className={`league-content ${expandedLeagues[league.id] ? 'expanded' : ''}`}>
                            <div className="stats-grid">
                                <div className="stat-card">
                                    <FaTrophy className="stat-icon" />
                                    <div className="stat-value">{leagueStats[league.id]?.winRate}%</div>
                                    <div className="stat-label">Win Rate</div>
                                </div>
                                
                                <div className="stat-card">
                                    <FaGamepad className="stat-icon" />
                                    <div className="stat-value">{leagueStats[league.id]?.betsCreated}</div>
                                    <div className="stat-label">Bets Created</div>
                                </div>

                                <div className="stat-card">
                                    <FaChartLine className="stat-icon" />
                                    <div className="stat-value">{leagueStats[league.id]?.betsJoined}</div>
                                    <div className="stat-label">Bets Joined</div>
                                </div>

                                <div className="stat-card">
                                    <FaDollarSign className="stat-icon" />
                                    <div className="stat-value">${leagueStats[league.id]?.totalEarnings}</div>
                                    <div className="stat-label">Total Earnings</div>
                                </div>

                                <div className="stat-card">
                                    <FaPercentage className="stat-icon" />
                                    <div className="stat-value">{leagueStats[league.id]?.participationRate}%</div>
                                    <div className="stat-label">Participation Rate</div>
                                </div>

                                <div className="stat-card">
                                    <FaFire className="stat-icon" />
                                    <div className="stat-value">{leagueStats[league.id]?.currentStreak}</div>
                                    <div className="stat-label">Current Streak</div>
                                </div>

                                <div className="stat-card">
                                    <FaBolt className="stat-icon" />
                                    <div className="stat-value">{leagueStats[league.id]?.bestStreak}</div>
                                    <div className="stat-label">Best Streak</div>
                                </div>

                                <div className="stat-card">
                                    <FaClock className="stat-icon" />
                                    <div className="stat-value">{getTimeAgo(leagueStats[league.id]?.lastActivity)}</div>
                                    <div className="stat-label">Last Activity</div>
                                </div>

                                <div className="stat-card">
                                    <FaDollarSign className="stat-icon" />
                                    <div className="stat-value">${leagueStats[league.id]?.averageBetAmount}</div>
                                    <div className="stat-label">Avg Bet Amount</div>
                                </div>

                                <div className="stat-card">
                                    <FaUsers className="stat-icon" />
                                    <div className="stat-value">{leagueStats[league.id]?.recentBets}</div>
                                    <div className="stat-label">Recent Activity</div>
                                </div>

                                <div className="stat-card">
                                    <FaCalendarAlt className="stat-icon" />
                                    <div className="stat-value">{leagueStats[league.id]?.pendingValidations}</div>
                                    <div className="stat-label">Pending Validations</div>
                                </div>

                                {leagueStats[league.id]?.rivalryStats?.mostConfronted?.count > 0 && (
                                    <div className="stat-card">
                                        <FaUserFriends className="stat-icon" />
                                        <div className="stat-value">
                                            {leagueStats[league.id]?.rivalryStats?.mostConfronted?.username?.split('@')[0]}
                                            <div className="stat-subvalue">
                                                {leagueStats[league.id]?.rivalryStats?.mostConfronted?.count} matches
                                            </div>
                                        </div>
                                        <div className="stat-label">Most Confronted</div>
                                    </div>
                                )}

                                {leagueStats[league.id]?.rivalryStats?.mostWinsAgainst?.count > 0 && (
                                    <div className="stat-card">
                                        <FaTrophy className="stat-icon success" />
                                        <div className="stat-value">
                                            {leagueStats[league.id]?.rivalryStats?.mostWinsAgainst?.username?.split('@')[0]}
                                            <div className="stat-subvalue">
                                                {leagueStats[league.id]?.rivalryStats?.mostWinsAgainst?.count} wins 
                                                ({leagueStats[league.id]?.rivalryStats?.mostWinsAgainst?.winRate}%)
                                            </div>
                                        </div>
                                        <div className="stat-label">Best Record Against</div>
                                    </div>
                                )}

                                {leagueStats[league.id]?.rivalryStats?.mostLossesAgainst?.count > 0 && (
                                    <div className="stat-card">
                                        <FaSkull className="stat-icon danger" />
                                        <div className="stat-value">
                                            {leagueStats[league.id]?.rivalryStats?.mostLossesAgainst?.username?.split('@')[0]}
                                            <div className="stat-subvalue">
                                                {leagueStats[league.id]?.rivalryStats?.mostLossesAgainst?.count} losses 
                                                ({leagueStats[league.id]?.rivalryStats?.mostLossesAgainst?.lossRate}%)
                                            </div>
                                        </div>
                                        <div className="stat-label">Worst Record Against</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MainPage;