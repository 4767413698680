const publicVapidKey = 'BGhM4Ann81FUO6Sohnw0Dx-z5FjvdBp2s6ZgHgeBZhdonbxT4DmZQgGXRra7XuR7e9wKEAVrI5RzqfyTPSJunwk'; // Replace with your public VAPID key

function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

export async function subscribeToPushNotifications() {
    if (!('serviceWorker' in navigator)) {
        console.log('Service workers are not supported in this browser');
        return;
    }
    try {
        const registration = await navigator.serviceWorker.ready;
        const subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(publicVapidKey)
        });

        await fetch('https://b9s45cfxpe.execute-api.eu-west-3.amazonaws.com/prod/handle_subscription', {
            method: 'POST',
            body: JSON.stringify(subscription),
            headers: {
                'Content-Type': 'application/json'
            }
        });

        console.log('Push notification subscription successful');
    } catch (error) {
        console.error('Error subscribing to push notifications:', error);
    }
}