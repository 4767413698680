import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { useAuth } from './AuthContext';
import './Dashboard.css';
import { subscribeToPushNotifications } from './pushNotifications';

axios.defaults.withCredentials = true;
Modal.setAppElement('#root');

const Dashboard = () => {
    const [leagues, setLeagues] = useState([]);
    const [showJoinMenu, setShowJoinMenu] = useState(false);
    const [joinSecretCode, setJoinSecretCode] = useState('');
    const [message, setMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { user, signOut, refreshToken } = useAuth();
    console.log("user", user);
    useEffect(() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.ready.then(() => {
                subscribeToPushNotifications().catch(error => {
                    console.error('Error subscribing to push notifications:', error);
                });
            });
        }
    }, []);
    const fetchLeagues = useCallback(async () => {
        if (!user) {
            //navigate to loginpage
            return
        };
        setIsLoading(true);
        try {
            const response = await axios.post('https://b9s45cfxpe.execute-api.eu-west-3.amazonaws.com/prod/get_leagues', {
                email: user.email
            }, {
                headers: { 'Authorization': `Bearer ${user.token}` }
            });
            if (response.status === 200) {
                setLeagues(response.data.leagues);
            } else {
                throw new Error('Failed to fetch leagues');
            }
        } catch (error) {
            console.error('Failed to fetch leagues:', error);
            if (error.response && error.response.status === 401) {
                try {
                    await refreshToken();
                    fetchLeagues();
                } catch (refreshError) {
                    console.error('Token refresh failed:', refreshError);
                    setErrorMessage('Your session has expired. Please log in again.');
                    signOut();
                    navigate('/login');
                }
            } else {
                setErrorMessage('Failed to fetch leagues. Please try again later.');
            }
        } finally {
            setIsLoading(false);
        }
    }, [user, refreshToken, signOut, navigate]);

    useEffect(() => {
        if (!user) {
            navigate('/login');
        } else {
            fetchLeagues();
        }
    }, [user, navigate, fetchLeagues]);

    const handleJoinLeague = async () => {
        if (!user) return;
        try {
            const response = await axios.post('https://b9s45cfxpe.execute-api.eu-west-3.amazonaws.com/prod/join_league', 
            { 
                secretCode: joinSecretCode, 
                email: user.email 
            }, 
            {
                headers: { 'Authorization': `Bearer ${user.token}` }
            });
            if (response.data.success) {
                setMessage('Joined league successfully!');
                setShowJoinMenu(false);
                fetchLeagues();
            } else {
                setErrorMessage(response.data.message);
            }
        } catch (error) {
            console.error('Join league error:', error);
            if (error.response && error.response.status === 401) {
                try {
                    await refreshToken();
                    handleJoinLeague();
                } catch (refreshError) {
                    console.error('Token refresh failed:', refreshError);
                    setErrorMessage('Your session has expired. Please log in again.');
                    signOut();
                    navigate('/login');
                }
            } else if (error.response && error.response.data) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage('Failed to join league. Please try again later.');
            }
        }
    };

    const handleCreateLeagueClick = () => {
        navigate('/create-league');
    };

    const handleLeagueClick = (leagueId) => {
        navigate(`/leagues/${leagueId}`);
    };

    const handleLogout = () => {
        signOut();
        navigate('/login');
    };

    if (!user) {
        return null; // or a loading spinner
    }

    return (
        <div className="dashboard-container">
            {message && <p className="message">{message}</p>}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <div className="dashboard-buttons">
                <button className="join-league-button" onClick={() => setShowJoinMenu(true)}>Join League</button>
                <button className="create-league-button" onClick={handleCreateLeagueClick}>Create New League</button>
            </div>
            <Modal
                isOpen={showJoinMenu}
                onRequestClose={() => setShowJoinMenu(false)}
                contentLabel="Join League Modal"
            >
                <div className="modal-content">
                    <h2>Join a League</h2>
                    <input
                        type="text"
                        value={joinSecretCode}
                        onChange={(e) => setJoinSecretCode(e.target.value)}
                        placeholder="Enter Secret Code"
                    />
                    <div className="modal-buttons">
                        <button className="join-button" onClick={handleJoinLeague}>Join</button>
                        <button className="cancel-button" onClick={() => setShowJoinMenu(false)}>Cancel</button>
                    </div>
                </div>
            </Modal>
            {isLoading ? (
                <p className="loading-message">Loading leagues...</p>
            ) : leagues.length > 0 ? (
                <div className="leagues-list">
                    {leagues.map((league) => (
                        <div key={league.id} className="league-item" onClick={() => handleLeagueClick(league.id)}>
                            <h3>{league.name}</h3>
                            <p>{league.description}</p>
                        </div>
                    ))}
                </div>
            ) : (
                <p className="no-leagues-message">No leagues found. Join or create a league to get started!</p>
            )}
        </div>
    );
};

export default Dashboard;
