import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment-timezone';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { useAuth } from './AuthContext';
import './ProfilePage.css';

// Configure Cognito User Pool
const poolData = {
    UserPoolId: 'eu-west-3_HluU4UOFJ',
    ClientId: '51i3600jkv5f4cgf5ing1142sg'
};
const userPool = new CognitoUserPool(poolData);

const timezones = moment.tz.names();

const ProfilePage = ({ closeSidebar }) => {
    const [username, setUsername] = useState('');
    const [timezone, setTimezone] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState('');
    const [isPushEnabled, setIsPushEnabled] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { user, signOut } = useAuth();
    const token = user ? user.token : null;
    const email = user ? user.email : null;

    useEffect(() => {
        // Close sidebar only when navigating to this page
        if (location.state && location.state.fromNavigation) {
            if (typeof closeSidebar === 'function') {
                closeSidebar();
            }
        }
    
        const fetchProfile = async () => {
            try {
                const response = await axios.post('https://b9s45cfxpe.execute-api.eu-west-3.amazonaws.com/prod/user_profile', { email }, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                if (response.status === 200) {
                    setUsername(response.data.username);
                    setTimezone(response.data.timezone);
                } else {
                    throw new Error('Failed to fetch profile');
                }
            } catch (error) {
                console.error('Failed to fetch profile:', error);
                setError('Failed to fetch profile');
            } finally {
                setLoading(false);
            }
        };

        const checkUserSession = () => {
            const cognitoUser = userPool.getCurrentUser();
            if (cognitoUser) {
                cognitoUser.getSession((err, session) => {
                    if (err || !session.isValid()) {
                        localStorage.setItem('redirectAfterLogin', window.location.pathname);
                        navigate('/login', { replace: true });
                    } else {
                        fetchProfile();
                    }
                });
            } else {
                localStorage.setItem('redirectAfterLogin', window.location.pathname);
                navigate('/login', { replace: true });
            }
        };

        checkUserSession();
    }, [navigate, closeSidebar, email, token, location]);

    const handleSave = async () => {
        try {
            const response = await axios.post('https://b9s45cfxpe.execute-api.eu-west-3.amazonaws.com/prod/update_timezone', { email, timezone }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            if (response.status === 200) {
                setSuccess('Timezone updated successfully');
            } else {
                setError('Failed to update timezone');
            }
        } catch (error) {
            console.error('Failed to update timezone:', error);
            setError('Failed to update timezone');
        }
    };

    const handleBackToDashboard = () => {
        navigate('/dashboard');
    };

    const handleLogout = async () => {
        try {
            await signOut();
            navigate('/login', { replace: true });
        } catch (error) {
            console.error('Logout failed:', error);
            setError('Logout failed. Please try again.');
        }
    };

    const handlePushSubscription = async () => {
        if (!('serviceWorker' in navigator) || !('PushManager' in window)) {
            alert('Push notifications are not supported in this browser');
            return;
        }
    
        try {
            const registration = await navigator.serviceWorker.ready;
            console.log("Service Worker is ready");
    
            let subscription = await registration.pushManager.getSubscription();
            console.log("Current subscription:", subscription);
    
            if (subscription) {
                // User is already subscribed, so unsubscribe
                console.log("Unsubscribing...");
                const unsubscribed = await subscription.unsubscribe();
                if (unsubscribed) {
                    console.log("Unsubscribed successfully");
                    setIsPushEnabled(false);
                    alert('Push notifications disabled');
                } else {
                    throw new Error("Failed to unsubscribe");
                }
            } else {
                // Subscribe the user
                console.log("Subscribing...");
                try {
                    const applicationServerKey = urlBase64ToUint8Array('BGhM4Ann81FUO6Sohnw0Dx-z5FjvdBp2s6ZgHgeBZhdonbxT4DmZQgGXRra7XuR7e9wKEAVrI5RzqfyTPSJunwk');
                    console.log("Application Server Key:", applicationServerKey);
                    
                    subscription = await registration.pushManager.subscribe({
                        userVisibleOnly: true,
                        applicationServerKey: applicationServerKey
                    });
                    console.log("Subscribed successfully:", subscription);
                } catch (subscribeError) {
                    console.error("Subscription error:", subscribeError);
                    alert(`Subscription error: ${subscribeError.message}`);
                    return;
                }
    
                // Send the subscription to your server
                console.log("Sending subscription to server...");
                const response = await axios.post('https://b9s45cfxpe.execute-api.eu-west-3.amazonaws.com/prod/handle_subscription', 
                    { subscription: JSON.stringify(subscription), email },
                    { 
                        headers: { 
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        } 
                    }
                );
    
                console.log("Server response:", response);
    
                if (response.status === 200) {
                    setIsPushEnabled(true);
                    alert('Push notifications enabled');
                } else {
                    throw new Error('Failed to subscribe to push notifications');
                }
            }
        } catch (error) {
            console.error('Error handling push subscription:', error);

            alert(`Failed to handle push notification subscription: ${error.message}`);
            
        }
    };

    // Helper function to convert base64 to Uint8Array
    function urlBase64ToUint8Array(base64String) {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
            .replace(/\-/g, '+')
            .replace(/_/g, '/');
    
        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);
    
        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }

    if (loading) {
        return (
            <div className="loading-container">
                <div className="loading-spinner"></div>
                <p>Loading profile...</p>
            </div>
        );
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <div className="profile-container">
            <div className="profile-header">
                <h1>Profile</h1>
                <div className="profile-header-buttons">
                    <button className="icon-button back-button" onClick={handleBackToDashboard} aria-label="Back to Dashboard">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                            <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"/>
                        </svg>
                    </button>
                </div>
            </div>
            <div className="profile-section">
                <h2>Username</h2>
                <p>{username}</p>
            </div>
            <div className="profile-section">
                <h2>Preferred Timezone</h2>
                <label htmlFor="timezone">Select Timezone:</label>
                <select
                    id="timezone"
                    value={timezone}
                    onChange={(e) => setTimezone(e.target.value)}
                    required
                >
                    <option value="">Select Timezone</option>
                    {timezones.map((tz, index) => (
                        <option key={index} value={tz}>
                            {tz}
                        </option>
                    ))}
                </select>
                <div className="dashboard-buttons">
                    <button className="save-button" onClick={handleSave}>Save</button>
                    <button className="save-button" onClick={handleBackToDashboard}>Back to Dashboard</button>
                    <button className="logout-button" onClick={handleLogout}>Logout</button>
                </div>
                {success && <div className="success-message">{success}</div>}
                {error && <div className="error-message">{error}</div>}
            </div>
            <div className="profile-section">
                <h2>Push Notifications</h2>
                <button className="push-button" onClick={handlePushSubscription}>
                    {isPushEnabled ? 'Disable' : 'Enable'} Push Notifications
                </button>
            </div>
        </div>
    );
};

export default ProfilePage;