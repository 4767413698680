import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment-timezone';
import './LeagueOutcome.css';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { useAuth } from './AuthContext';

const poolData = {
    UserPoolId: 'eu-west-3_HluU4UOFJ',
    ClientId: '51i3600jkv5f4cgf5ing1142sg'
};

const userPool = new CognitoUserPool(poolData);
axios.defaults.withCredentials = true;

const INITIAL_DISPLAY_COUNT = 5;
const LOAD_MORE_COUNT = 5;

const BetCard = ({ bet, userDetails, email, status, getBetStatusCircle }) => {
    const navigate = useNavigate();
    return (
        <div className="bet-card clickable" onClick={() => navigate(`/bets/${bet.betId}`)}>
            {getBetStatusCircle(status)}
            <div className="bet-content">
                <div className="bet-detail">
                    <p><strong>Details:</strong> {bet.betDetails}</p>
                    <p><strong>Amount:</strong> ${bet.amount}</p>
                    <p><strong>Type:</strong> {bet.betType === 'tombola' ? 'Tombola' : 'Regular'}</p>
                </div>
                {bet.betType === 'tombola' ? (
                    <div className="bet-participants">
                        <p><strong>Participants:</strong></p>
                        {Object.entries(bet.outcomes).map(([email, outcome]) => (
                            <p key={email}>{userDetails[email] || outcome.nickname || 'Unknown'}: {outcome.outcome}</p>
                        ))}
                    </div>
                ) : (
                    <div className="bet-participants">
                        <p><strong>For:</strong> {bet.bet_for.map(userId => userDetails[userId] || 'Unknown').join(', ')}</p>
                        <p><strong>Against:</strong> {bet.bet_against.map(userId => userDetails[userId] || 'Unknown').join(', ')}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

const TableView = ({ 
    leagueOutcome, 
    userDetails, 
    displayedBets, 
    loadPreviousBets, 
    hasMoreBets,
    allValidatedBets,
    monthlyTotals 
}) => {
    const totalScores = useMemo(() => {
        const scores = Object.fromEntries(leagueOutcome.members.map(member => [member._id, 0]));
        
        allValidatedBets.forEach(bet => {
            if (bet.betType === 'tombola') {
                const validatedOutcome = bet.close_request[0].winning_outcome;
                const winner = Object.entries(bet.outcomes).find(([email, data]) => data.outcome === validatedOutcome);
                if (winner) {
                    const [winnerEmail, winnerData] = winner;
                    const totalAmount = bet.amount * (Object.keys(bet.outcomes).length - 1);
                    Object.entries(bet.outcomes).forEach(([email, data]) => {
                        if (email === winnerEmail) {
                            scores[email] += totalAmount;
                        } else {
                            scores[email] -= bet.amount;
                        }
                    });
                }
            } else {
                const forUserWon = bet.close_request[0].outcome === 'for';
                if (forUserWon) {
                    const totalAmount = bet.amount * bet.bet_against.length;
                    bet.bet_for.forEach(userId => {
                        scores[userId] += totalAmount;
                    });
                    bet.bet_against.forEach(userId => {
                        scores[userId] -= bet.amount;
                    });
                } else {
                    const totalAmount = bet.amount * bet.bet_against.length;
                    bet.bet_for.forEach(userId => {
                        scores[userId] -= totalAmount;
                    });
                    bet.bet_against.forEach(userId => {
                        scores[userId] += bet.amount;
                    });
                }
            }
        });
    
        return scores;
    }, [leagueOutcome, allValidatedBets]);
    
    const tableData = useMemo(() => {
        return displayedBets
            .sort((a, b) => moment(a.event_deadline_timestamp).diff(moment(b.event_deadline_timestamp)))
            .map(bet => {
                if (bet.betType === 'tombola') {
                    const scores = Object.fromEntries(leagueOutcome.members.map(member => [member._id, 0]));
                    const validatedOutcome = bet.close_request[0].winning_outcome;
                    const winner = Object.entries(bet.outcomes).find(([email, data]) => data.outcome === validatedOutcome);
                    if (winner) {
                        const [winnerEmail, winnerData] = winner;
                        const totalAmount = bet.amount * (Object.keys(bet.outcomes).length - 1);
                        Object.keys(bet.outcomes).forEach(email => {
                            if (email === winnerEmail) {
                                scores[email] += totalAmount;
                            } else {
                                scores[email] -= bet.amount;
                            }
                        });
                    }
                    return {
                        betId: bet.betId,
                        betDetails: `Tombola: ${bet.betDetails}`,
                        date: moment(bet.event_deadline_timestamp).format('YYYY-MM'),
                        ...scores
                    };
                } else {
                    const scores = {};
                    const forUserWon = bet.close_request[0].outcome === 'for';
                    const totalAmount = bet.amount * bet.bet_against.length;

                    if (forUserWon) {
                        bet.bet_for.forEach(userId => { scores[userId] = totalAmount; });
                        bet.bet_against.forEach(userId => { scores[userId] = -bet.amount; });
                    } else {
                        bet.bet_for.forEach(userId => { scores[userId] = -totalAmount; });
                        bet.bet_against.forEach(userId => { scores[userId] = bet.amount; });
                    }
                    return {
                        betId: bet.betId,
                        betDetails: bet.betDetails,
                        date: moment(bet.event_deadline_timestamp).format('YYYY-MM'),
                        ...Object.fromEntries(leagueOutcome.members.map(member => [member._id, 0])),
                        ...scores
                    };
                }
            });
    }, [displayedBets, leagueOutcome]);

    return (
        <div className="table-view">
            {hasMoreBets && (
                <button onClick={loadPreviousBets} className="load-previous-button">
                    Load Earlier Bets
                </button>
            )}
            <table>
                <thead>
                    <tr>
                        <th>Bet Details</th>
                        {leagueOutcome.members.map(member => (
                            <th key={member._id}>{userDetails[member._id] || 'Unknown'}</th>
                        ))}
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {tableData.map((bet) => (
                        <tr key={bet.betId}>
                            <td>{bet.betDetails}</td>
                            {leagueOutcome.members.map(member => (
                                <td key={member._id}>{bet[member._id] || 0}</td>
                            ))}
                            <td>{moment(bet.date).format('MMMM YYYY')}</td>
                        </tr>
                    ))}
                    <tr className="total-row">
                        <td><strong>Total</strong></td>
                        {leagueOutcome.members.map(member => (
                            <td key={member._id}><strong>{totalScores[member._id]}</strong></td>
                        ))}
                        <td></td>
                    </tr>
                    {Object.entries(monthlyTotals || {}).sort(([a], [b]) => a.localeCompare(b)).map(([month, scores]) => (
                        <tr key={month} className="monthly-total-row">
                            <td><strong>Monthly Total</strong></td>
                            {leagueOutcome.members.map(member => (
                                <td key={member._id}><strong>{scores[member._id]}</strong></td>
                            ))}
                            <td><strong>{moment(month).format('MMMM YYYY')}</strong></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

const LeagueOutcome = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [leagueOutcome, setLeagueOutcome] = useState(null);
    const [userDetails, setUserDetails] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [displayCounts, setDisplayCounts] = useState({
        validated: INITIAL_DISPLAY_COUNT,
        awaitingValidation: INITIAL_DISPLAY_COUNT,
        noValidationRequests: INITIAL_DISPLAY_COUNT
    });
    const [showTableView, setShowTableView] = useState(false);
    const [displayedBetsCount, setDisplayedBetsCount] = useState(INITIAL_DISPLAY_COUNT);
    const token = localStorage.getItem('token');
    const stored_email = localStorage.getItem('email');
    const { user } = useAuth();
    let email = stored_email || user?.email;

    const fetchUserDetails = async (userId) => {
        try {
            const response = await axios.post('https://b9s45cfxpe.execute-api.eu-west-3.amazonaws.com/prod/user_profile', {
                email: userId
            }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            return response.status === 200 ? response.data.username : null;
        } catch (error) {
            console.error(`Failed to fetch user details for ${userId}:`, error);
            return null;
        }
    };

    useEffect(() => {
        const fetchLeagueOutcome = async () => {
            try {
                const response = await axios.post('https://b9s45cfxpe.execute-api.eu-west-3.amazonaws.com/prod/get_league_outcome', {
                    id,
                    email
                }, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                
                if (response.status === 200) {
                    setLeagueOutcome(response.data);
                    
                    const userDetailsPromises = response.data.members.map(async member => {
                        const username = await fetchUserDetails(member._id);
                        return { [member._id]: username };
                    });

                    const userDetailsArray = await Promise.all(userDetailsPromises);
                    const userDetailsObject = userDetailsArray.reduce((acc, cur) => ({ ...acc, ...cur }), {});

                    setUserDetails(userDetailsObject);
                } else {
                    throw new Error('Failed to fetch league outcome');
                }
            } catch (error) {
                console.error('Failed to fetch league outcome:', error);
                setError('Failed to fetch league outcome.');
            } finally {
                setLoading(false);
            }
        };

        const checkUserSession = () => {
            const cognitoUser = userPool.getCurrentUser();
            if (cognitoUser) {
                cognitoUser.getSession((err, session) => {
                    if (err || !session.isValid()) {
                        localStorage.setItem('redirectAfterLogin', window.location.pathname);
                        navigate('/login', { replace: true });
                    } else {
                        fetchLeagueOutcome();
                    }
                });
            } else {
                navigate('/login', { replace: true });
            }
        };

        checkUserSession();
    }, [id, navigate, token, email]);

    useEffect(() => {
        const refreshButtonContainer = document.createElement('div');
        refreshButtonContainer.className = 'refresh-button-container';

        const refreshButton = document.createElement('button');
        refreshButton.className = 'refresh-button';
        refreshButton.setAttribute('aria-label', 'Refresh page');
        refreshButton.innerHTML = `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M23 4v6h-6"></path>
                <path d="M1 20v-6h6"></path>
                <path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path>
            </svg>
        `;
        refreshButton.onclick = () => window.location.reload();

        refreshButtonContainer.appendChild(refreshButton);

        const header = document.querySelector('.league-outcome-header');
        if (header && header.parentNode) {
            header.parentNode.insertBefore(refreshButtonContainer, header.nextSibling);
        }

        return () => {
            if (refreshButtonContainer.parentNode) {
                refreshButtonContainer.parentNode.removeChild(refreshButtonContainer);
            }
        };
    }, []);

    const allValidatedBets = useMemo(() => {
        if (!leagueOutcome || !leagueOutcome.bets) return [];
        return leagueOutcome.bets
            .filter(bet => bet.bet_validated)
            .sort((a, b) => moment(a.event_deadline_timestamp).diff(moment(b.event_deadline_timestamp)));
    }, [leagueOutcome]);

    const monthlyTotals = useMemo(() => {
        if (!leagueOutcome || !leagueOutcome.members) return {};
        
        const totals = {};
        allValidatedBets.forEach(bet => {
            const date = moment(bet.event_deadline_timestamp).format('YYYY-MM');
            if (!totals[date]) {
                totals[date] = Object.fromEntries(leagueOutcome.members.map(member => [member._id, 0]));
            }
            
            if (bet.betType === 'tombola') {
                if (bet.close_requests && typeof bet.close_requests === 'string') {
                    try {
                        const closeRequests = JSON.parse(bet.close_requests);
                        if (closeRequests.length > 0) {
                            const validatedOutcome = closeRequests[0].winning_outcome;
                            if (bet.outcomes && validatedOutcome) {
                                let parsedOutcomes = bet.outcomes;
                                if (typeof bet.outcomes === 'string') {
                                    try {
                                        parsedOutcomes = JSON.parse(bet.outcomes);
                                    } catch (e) {
                                        console.error('Failed to parse outcomes:', e);
                                        return;
                                    }
                                }
    
                                const winner = Object.entries(parsedOutcomes).find(([_, data]) => {
                                    const outcomeData = typeof data === 'string' ? JSON.parse(data) : data;
                                    return outcomeData.outcome === validatedOutcome;
                                });
    
                                if (winner) {
                                    const [winnerEmail, _] = winner;
                                    const totalAmount = bet.amount * (Object.keys(parsedOutcomes).length - 1);
                                    
                                    Object.keys(parsedOutcomes).forEach(email => {
                                        if (email === winnerEmail) {
                                            totals[date][email] = (totals[date][email] || 0) + totalAmount;
                                        } else {
                                            totals[date][email] = (totals[date][email] || 0) - bet.amount;
                                        }
                                    });
                                }
                            }
                        }
                    } catch (e) {
                        console.error('Failed to parse close_requests:', e);
                    }
                }
            } else {
                if (bet.close_request && bet.close_request.length > 0) {
                    const forUserWon = bet.close_request[0].outcome === 'for';
                    const totalAmount = bet.amount * bet.bet_against.length;
    
                    if (forUserWon) {
                        bet.bet_for.forEach(userId => {
                            totals[date][userId] = (totals[date][userId] || 0) + totalAmount;
                        });
                        bet.bet_against.forEach(userId => {
                            totals[date][userId] = (totals[date][userId] || 0) - bet.amount;
                        });
                    } else {
                        bet.bet_for.forEach(userId => {
                            totals[date][userId] = (totals[date][userId] || 0) - totalAmount;
                        });
                        bet.bet_against.forEach(userId => {
                            totals[date][userId] = (totals[date][userId] || 0) + bet.amount;
                        });
                    }
                }
            }
        });
        return totals;
    }, [leagueOutcome, allValidatedBets]);

    const calculateScores = useMemo(() => {
        if (!leagueOutcome || !leagueOutcome.bets) return {};
    
        const scores = Object.fromEntries(leagueOutcome.members.map(member => [member._id, 0]));
        
        allValidatedBets.forEach(bet => {
            if (bet.betType === 'tombola') {
                const validatedOutcome = bet.close_request[0].winning_outcome;
                const winner = Object.entries(bet.outcomes).find(([email, data]) => data.outcome === validatedOutcome);
                if (winner) {
                    const [winnerEmail, winnerData] = winner;
                    const totalAmount = bet.amount * (Object.keys(bet.outcomes).length - 1);
                    Object.entries(bet.outcomes).forEach(([email, data]) => {
                        if (email === winnerEmail) {
                            scores[email] += totalAmount;
                        } else {
                            scores[email] -= bet.amount;
                        }
                    });
                }
            } else {
                const forUserWon = bet.close_request[0].outcome === 'for';
                if (forUserWon) {
                    const totalAmount = bet.amount * bet.bet_against.length;
                    bet.bet_for.forEach(userId => {
                        scores[userId] += totalAmount;
                    });
                    bet.bet_against.forEach(userId => {
                        scores[userId] -= bet.amount;
                    });
                } else {
                    const totalAmount = bet.amount * bet.bet_against.length;
                    bet.bet_for.forEach(userId => {
                        scores[userId] -= totalAmount;
                    });
                    bet.bet_against.forEach(userId => {
                        scores[userId] += bet.amount;
                    });
                }
            }
        });
    
        return scores;
    }, [leagueOutcome, allValidatedBets]);

    const getBetStatusCircle = (status) => {
        let color;
        switch(status) {
            case 'validated':
                color = '#4CAF50';
                break;
            case 'awaiting':
                color = '#FFA500';
                break;
            case 'noRequest':
                color = '#3498db';
                break;
            default:
                color = '#808080';
        }
        return <span className={`status-circle ${status}`} style={{backgroundColor: color}}></span>;
    };

    const loadMore = (category) => {
        setDisplayCounts(prev => ({
            ...prev,
            [category]: prev[category] + LOAD_MORE_COUNT
        }));
    };

    const renderBetSection = (title, bets, status, categoryKey) => {
        const displayedBets = bets.slice(0, displayCounts[categoryKey]);
        return (
            <div className="bet-section">
                <h3>{getBetStatusCircle(status)} {title}</h3>
                {displayedBets.map((bet, index) => (
                    <BetCard 
                        key={index} 
                        bet={bet} 
                        userDetails={userDetails} 
                        email={email}
                        status={status}
                        getBetStatusCircle={getBetStatusCircle}
                    />
                ))}
                {bets.length > displayCounts[categoryKey] && (
                    <button className="load-more-button" onClick={() => loadMore(categoryKey)}>
                        Load More
                    </button>
                )}
            </div>
        );
    };

    const groupedBets = useMemo(() => {
        if (!leagueOutcome || !leagueOutcome.bets) return { validated: [], awaitingValidation: [], noValidationRequests: [] };

        const now = moment();
        const grouped = leagueOutcome.bets.reduce((acc, bet) => {
            const deadline = moment(bet.event_deadline_timestamp);
            if (now.isAfter(deadline)) {
                if (bet.betType === 'tombola') {
                    if (bet.bet_validated) {
                        acc.validated.push(bet);
                    }
                } else if (bet.bet_against && bet.bet_against.length > 0) {
                    if (bet.bet_validated) {
                        acc.validated.push(bet);
                    } else if (bet.close_request && bet.close_request.length > 0) {
                        acc.awaitingValidation.push(bet);
                    } else {
                        acc.noValidationRequests.push(bet);
                    }
                }
            }
            return acc;
        }, { validated: [], awaitingValidation: [], noValidationRequests: [] });

        grouped.validated.sort((a, b) => moment(b.validation_timestamp).diff(moment(a.validation_timestamp)));
        const sortByDeadline = (a, b) => moment(b.event_deadline_timestamp).diff(moment(a.event_deadline_timestamp));
        grouped.awaitingValidation.sort(sortByDeadline);
        grouped.noValidationRequests.sort(sortByDeadline);

        return grouped;
    }, [leagueOutcome]);

    const sortedValidatedBets = useMemo(() => {
        if (!leagueOutcome || !leagueOutcome.bets) return [];
        return leagueOutcome.bets
            .filter(bet => bet.bet_validated && bet.close_request && bet.close_request.length > 0)
            .sort((a, b) => moment(b.event_deadline_timestamp).diff(moment(a.event_deadline_timestamp)));
    }, [leagueOutcome]);

    const displayedBets = useMemo(() => {
        return sortedValidatedBets.slice(0, displayedBetsCount);
    }, [sortedValidatedBets, displayedBetsCount]);

    const loadPreviousBets = () => {
        setDisplayedBetsCount(prev => Math.min(prev + LOAD_MORE_COUNT, sortedValidatedBets.length));
    };

    if (loading) {
        return (
            <div className="loading-container">
                <div className="loading-spinner"></div>
                <p>Loading league details...</p>
            </div>
        );
    }
    if (error) return <div className="error">Error: {error}</div>;
    if (!leagueOutcome) return <div className="no-data">No data available</div>;

    return (
        <div className="league-outcome-container">
            <div className="league-outcome-header">
                <h1>League Outcome</h1>
                <h2>{leagueOutcome?.leagueName}</h2>
            </div>
            
            <div className="button-group">
                <button onClick={() => navigate(`/leagues/${id}`)} className="action-button back-league-button">
                    Back to League Details
                </button>
                <button onClick={() => setShowTableView(!showTableView)} className="action-button toggle-view-button">
                    {showTableView ? "Show Detailed View" : "Show Results as Table"}
                </button>
            </div>
            
            {showTableView ? (
                <TableView 
                    leagueOutcome={leagueOutcome} 
                    userDetails={userDetails} 
                    displayedBets={displayedBets}
                    loadPreviousBets={loadPreviousBets}
                    hasMoreBets={displayedBetsCount < sortedValidatedBets.length}
                    allValidatedBets={allValidatedBets}
                    monthlyTotals={monthlyTotals}
                />
            ) : (
                <>
                    <div className="summary">
                        <h3>Total Scores</h3>
                        {leagueOutcome.members.map((member) => (
                            <div key={member._id} className="score-card">
                                <p>{userDetails[member._id] || 'Unknown'}: {calculateScores[member._id]}$</p>
                            </div>
                        ))}
                    </div>

                    <div className="bets-sections">
                        {renderBetSection("Validated Bets", groupedBets.validated, "validated", "validated")}
                        {renderBetSection("Awaiting Validation", groupedBets.awaitingValidation, "awaiting", "awaitingValidation")}
                        {renderBetSection("No Validation Requests", groupedBets.noValidationRequests, "noRequest", "noValidationRequests")}
                    </div>
                </>
            )}
        </div>
    );
};

export default LeagueOutcome;