import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useAuth } from './AuthContext';
import moment from 'moment-timezone';
import ValidationPopup from './ValidationPopup';
import './ValidationPopup.css';
import Modal from 'react-modal'; 

const ValidationPopupManager = () => {
    const [betsToValidate, setBetsToValidate] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [showPopup, setShowPopup] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [confirmationAction, setConfirmationAction] = useState(null); 

    const { user } = useAuth();
    const token = localStorage.getItem('token');
    const email = user?.email || localStorage.getItem('email');

    const modalRootRef = useRef(null);

    useEffect(() => {
        fetchBetsNeedingValidation();
        if (modalRootRef.current) { 
            Modal.setAppElement(modalRootRef.current); 
        }
    }, []);

    const fetchBetsNeedingValidation = async () => {
        try {
            setLoading(true);
            const response = await axios.post(
                'https://b9s45cfxpe.execute-api.eu-west-3.amazonaws.com/prod/get_close_requests_by_user',
                { email },
                { headers: { 'Authorization': `Bearer ${token}` } }
            );

            if (response.status === 200) {
                const validationNeeded = response.data.close_requests.filter(request => {
                    const eventDeadline = moment(request.event_deadline_timestamp);
                    return eventDeadline.isBefore(moment());
                });

                setBetsToValidate(validationNeeded);
                setShowPopup(validationNeeded.length > 0);
            }
        } catch (error) {
            console.error('Failed to fetch bets needing validation:', error);
            setError('Failed to fetch bets that need validation');
        } finally {
            setLoading(false);
        }
    };

    const handleValidate = async (betId) => {
        try {
            const response = await axios.post(
                'https://b9s45cfxpe.execute-api.eu-west-3.amazonaws.com/prod/validate_close_bet',
                { betId, email, accept: true },
                { headers: { 'Authorization': `Bearer ${token}` } }
            );

            if (response.status === 200) {
                setBetsToValidate(prev => prev.filter(bet => bet.betId !== betId));
                moveToNextBet();
            }
        } catch (error) {
            console.error('Failed to validate bet:', error);
            setError('Failed to validate bet. Please try again.');
        }
    };

    const handleChallenge = async (betId) => {
        try {
            const response = await axios.post(
                'https://b9s45cfxpe.execute-api.eu-west-3.amazonaws.com/prod/validate_close_bet',
                { betId, email, accept: false },
                { headers: { 'Authorization': `Bearer ${token}` } }
            );

            if (response.status === 200) {
                setBetsToValidate(prev => prev.filter(bet => bet.betId !== betId));
                moveToNextBet();
            }
        } catch (error) {
            console.error('Failed to challenge bet:', error);
            setError('Failed to challenge bet. Please try again.');
        }
    };

    const moveToNextBet = () => {
        if (currentIndex >= betsToValidate.length - 1) {
            setShowPopup(false);
        } else {
            setCurrentIndex(prev => prev + 1);
        }
    };

    const confirmAction = (action, betId) => {
        setConfirmationAction({ action, betId });
    };

    const handleConfirm = () => {
        if (confirmationAction?.action === 'validate') {
            handleValidate(confirmationAction.betId);
        } else if (confirmationAction?.action === 'challenge') {
            handleChallenge(confirmationAction.betId);
        }
        setConfirmationAction(null); 
    };

    if (loading) {
        return null; 
    }

    if (!showPopup || betsToValidate.length === 0) {
        return null;
    }

    const currentBet = betsToValidate[currentIndex];

    return (
        <> 
            <div ref={modalRootRef} /> 
            {showPopup && betsToValidate.length > 0 && (
                <ValidationPopup
                    bet={currentBet}
                    currentIndex={currentIndex}
                    totalBets={betsToValidate.length}
                    onValidate={() => confirmAction('validate', currentBet.betId)}
                    onChallenge={() => confirmAction('challenge', currentBet.betId)}
                    onSkip={moveToNextBet}
                    onEnd={() => setShowPopup(false)}
                    showValidateConfirm={confirmationAction?.action === 'validate'}
                    showChallengeConfirm={confirmationAction?.action === 'challenge'}
                    onConfirm={handleConfirm}
                    onCancel={() => setConfirmationAction(null)}
                />
            )}
        </>
    );
};

export default ValidationPopupManager;