import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import './LoginPage.css';

function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { user, loading, signIn } = useAuth();

    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!email || !password) {
            setError('Please enter both email and password');
            return;
        }

        setError('');
        setIsLoading(true);

        try {
            const result = await signIn(email, password);
            console.log('Sign in result:', result);
            
            if (result.challengeName === 'NEW_PASSWORD_REQUIRED') {
                console.log('Password change required, navigating...');
                // Immediate navigation
                navigate('/new-password-required', { 
                    state: { email },
                    replace: true 
                });
                return;
            }

            const redirectUrl = localStorage.getItem('redirectAfterLogin') || '/dashboard';
            localStorage.removeItem('redirectAfterLogin');
            navigate(redirectUrl, { replace: true });
        } catch (err) {
            console.error('Login error:', err);
            if (err.code === 'UserNotConfirmedException') {
                navigate('/confirm-signup', { state: { email } });
            } else {
                setError(err.message || 'An error occurred during login. Please try again.');
            }
        } finally {
            setIsLoading(false);
        }
    }, [email, password, navigate, signIn]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (user) {
        navigate('/dashboard', { replace: true });
        return null;
    }

    return (
        <div className="login-container">
            <h1>Login</h1>
            {error && <div className="error-message">{error}</div>}
            <form onSubmit={handleSubmit} className="login-form">
                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input
                        id="email"
                        type="email"
                        value={email}
                        onChange={e => setEmail(e.target.value.trim())}
                        required
                        autoComplete="email"
                        disabled={isLoading}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password:</label>
                    <input
                        id="password"
                        type="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        required
                        autoComplete="current-password"
                        disabled={isLoading}
                    />
                </div>
                <button 
                    type="submit" 
                    className={`login-button ${isLoading ? 'loading' : ''}`}
                    disabled={isLoading || !email || !password}
                >
                    {isLoading ? 'Logging in...' : 'Login'}
                </button>
                <button 
                    type="button" 
                    className="sign-up-button" 
                    onClick={() => navigate('/sign-up')}
                    disabled={isLoading}
                >
                    Sign Up
                </button>
                <button 
                    type="button" 
                    className="forgot-password-button" 
                    onClick={() => navigate('/forgot-password')}
                    disabled={isLoading}
                >
                    Forgot Password
                </button>
            </form>
        </div>
    );
}

export default LoginPage;