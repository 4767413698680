import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js'; // Import CognitoUser here
import './ForgotPasswordPage.css';  // Import the CSS file

const poolData = {
    UserPoolId: 'eu-west-3_HluU4UOFJ', // Replace with your User Pool ID
    ClientId: '51i3600jkv5f4cgf5ing1142sg' // Replace with your App Client ID
};

const userPool = new CognitoUserPool(poolData);

function ForgotPasswordPage() {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const handleBack = () => {
        navigate(-1); // This navigates to the previous page
    };
    const handleSubmit = (event) => {
        event.preventDefault();

        const cognitoUser = new CognitoUser({
            Username: email,
            Pool: userPool
        });

        cognitoUser.forgotPassword({
            onSuccess: (data) => {
                setMessage('Code sent to your email.');
                //navigate('/reset-password', { state: { email } });
            },
            onFailure: (err) => {
                setError(err.message || JSON.stringify(err));
            }
        });
    };

    return (
        <div className="forgot-password-container">
            <h1>Forgot Password</h1>
            <form onSubmit={handleSubmit} className="forgot-password-form">
                {error && <div className="error-message">{error}</div>} {/* Display error message */}
                {message && <div className="success-message">{message}</div>} {/* Display success message */}
                <div className="form-group">
                    <label>Email:</label>
                    <input
                        type="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        required
                        autoComplete="email"
                    />
                </div>
                <button type="submit" className="send-code-button">Send Verification Code</button>
                <div className="or-separator">OR</div>
                <button onClick={handleBack} className="send-code-button">← Back</button>
            </form>
        </div>
    );
}

export default ForgotPasswordPage;
