import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment-timezone';
import './UnseenBets.css';
import { useAuth } from './AuthContext';

const UnseenBets = () => {
    const [unseenBets, setUnseenBets] = useState([]);
    const [closeRequests, setCloseRequests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [closeRequestsLoading, setCloseRequestsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [closeRequestsError, setCloseRequestsError] = useState(null);
    const [visibleUnseenBets, setVisibleUnseenBets] = useState(1);
    const [visibleCloseRequests, setVisibleCloseRequests] = useState(1);
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const stored_email = localStorage.getItem('email');
    const { user } = useAuth();
    let email = stored_email || user?.email;

    useEffect(() => {
        fetchUnseenBets();
        fetchCloseRequests();
    }, []);

    const fetchUnseenBets = async () => {
        try {
            setLoading(true);
            const response = await axios.post('https://b9s45cfxpe.execute-api.eu-west-3.amazonaws.com/prod/get_unseen_bets', {
                email
            }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            if (response.status === 200) {
                const sortedBets = sortAndGroupBets(response.data.unseen_bets);
                setUnseenBets(sortedBets);
            } else {
                throw new Error('Failed to fetch unseen bets');
            }
        } catch (error) {
            console.error('Failed to fetch unseen bets:', error);
            setError('Failed to fetch unseen bets. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const fetchCloseRequests = async () => {
        try {
            setCloseRequestsLoading(true);
            const response = await axios.post('https://b9s45cfxpe.execute-api.eu-west-3.amazonaws.com/prod/get_close_requests_by_user', {
                email
            }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            if (response.status === 200) {
                if (!response.data.close_requests) {
                    setCloseRequests([]);
                    return;
                }
                setCloseRequests(response.data.close_requests);
            } else {
                throw new Error('Failed to fetch close requests');
            }
        } catch (error) {
            console.error('Failed to fetch close requests:', error);
            setCloseRequestsError('Failed to fetch close requests. Please try again.');
        } finally {
            setCloseRequestsLoading(false);
        }
    };

    const sortAndGroupBets = (bets) => {
        const now = moment();
        const validBets = bets.filter(bet => moment(bet.event_deadline_timestamp).isAfter(now));
        return validBets.sort((a, b) => moment(a.event_deadline_timestamp).diff(moment(b.event_deadline_timestamp)));
    };

    const markBetAsSeen = async (betId) => {
        try {
            await axios.post('https://b9s45cfxpe.execute-api.eu-west-3.amazonaws.com/prod/mark_bet_seen', {
                email,
                betId
            }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setUnseenBets(prevBets => prevBets.filter(bet => bet.betId !== betId));
        } catch (error) {
            console.error('Failed to mark bet as seen:', error);
        }
    };

    const markAllAsSeen = async () => {
        try {
            await axios.post('https://b9s45cfxpe.execute-api.eu-west-3.amazonaws.com/prod/mark_all_bets_seen', {
                email
            }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setUnseenBets([]);
        } catch (error) {
            console.error('Failed to mark all bets as seen:', error);
            setError('Failed to mark all bets as seen. Please try again.');
        }
    };

    const handleCloseRequestClick = (betId) => {
        navigate(`/bets/${betId}`);
    };

    if (loading || closeRequestsLoading) {
        return <div className="loading">Loading...</div>;
    }

    if (error || closeRequestsError) {
        return <div className="error">{error || closeRequestsError}</div>;
    }

    return (
        <div className="unseen-bets-container">
            <div className="unseen-bets-header">
                <h1>Notifications</h1>
                <div className="button-group">
                    <button onClick={() => {fetchUnseenBets(); fetchCloseRequests();}} className="refresh-button">Refresh</button>
                    <button onClick={markAllAsSeen} className="mark-all-seen-button">Clear unseen bets</button>
                </div>
            </div>

            {/* Close Requests Card */}
            <div className="card close-requests-card">
                <h2>Close Requests</h2>
                {closeRequests.length === 0 ? (
                    <div className="no-requests">No close requests available.</div>
                ) : (
                    <>
                        {closeRequests.slice(0, visibleCloseRequests).map(request => (
                            <CloseRequestCard 
                                key={request.betId} 
                                request={request} 
                                onClick={() => handleCloseRequestClick(request.betId)}
                            />
                        ))}
                        {visibleCloseRequests < closeRequests.length && (
                            <button onClick={() => setVisibleCloseRequests(prev => prev + 1)} className="load-more-button">
                                Load More Close Requests
                            </button>
                        )}
                    </>
                )}
            </div>

            {/* Unseen Bets Card */}
            <div className="card unseen-bets-card">
                <h2>Unseen Bets</h2>
                {unseenBets.length === 0 ? (
                    <div className="no-bets">No unseen bets available.</div>
                ) : (
                    <>
                        {unseenBets.slice(0, visibleUnseenBets).map(bet => (
                            <BetCard 
                                key={bet.betId} 
                                bet={bet} 
                                onBetClick={() => {
                                    markBetAsSeen(bet.betId);
                                    navigate(`/bets/${bet.betId}`);
                                }}
                            />
                        ))}
                        
                        {visibleUnseenBets < unseenBets.length && (
                            <button onClick={() => setVisibleUnseenBets(prev => prev + 1)} className="load-more-button">
                                Load More Unseen Bets
                            </button>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

const CloseRequestCard = ({ request, onClick }) => {
    return (
        <div className="close-request-card" onClick={onClick}>
            <div className="bet-urgency" style={{ backgroundColor: '#ffa500' }}></div>
            <div className="bet-content">
                <h3>Bet ID: {request.betId}</h3>
                <p><strong>Requester:</strong> {request.requester}</p>
                <p><strong>Outcome:</strong> {request.outcome}</p>
                <p><strong>Timestamp:</strong> {moment(request.timestamp).format('MMMM Do YYYY, h:mm:ss a')}</p>
            </div>
        </div>
    );
};

const BetCard = ({ bet, onBetClick }) => {
    const deadline = moment(bet.event_deadline_timestamp);
    const timeLeft = deadline.fromNow();

    return (
        <div className="bet-card" onClick={onBetClick}>
            <div className="bet-urgency" style={{ backgroundColor: getUrgencyColor(deadline) }}></div>
            <div className="bet-content">
                <h3>{bet.betDetails}</h3>
                <p><strong>Amount:</strong> ${bet.amount}</p>
                <p><strong>League Name:</strong> {bet.leagueName}</p>
                <p><strong>Deadline:</strong> {timeLeft}</p>
            </div>
        </div>
    );
};

const getUrgencyColor = (deadline) => {
    const now = moment();
    const hoursLeft = deadline.diff(now, 'hours');
    if (hoursLeft <= 6) return '#ff4d4d'; // Red
    if (hoursLeft <= 24) return '#ffa500'; // Orange
    return '#4CAF50'; // Green
};

export default UnseenBets;